import React, { useEffect, useMemo, useState } from "react"
import { DataGrid } from '@mui/x-data-grid';
import Modal from '@mui/material/Modal';
import Box from "@mui/material/Box";

const TrackingSessionDownloadTime = ({ data, CustomToolbar }) => {

    const [openModal, setOpenModal] = useState(false);
    const [detailByDate, setDetailByDate] = useState(false);
    const [detailDate, setDetailDate] = useState('');

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };

    const columns = [
        {
            field: 'userId',
            headerName: 'USER UUID',
            sortable: true,
            width: 300
        },
        {
            field: 'startTime',
            headerName: 'Start time',
            sortable: true,
            width: 300
        },
        {
            field: 'successfulTime',
            headerName: 'Successful Time',
            sortable: true,
            width: 300
        },
        {
            field: 'offsetTime',
            headerName: 'Offset',
            sortable: true,
            width: 150
        },
        {
            field: 'duration',
            headerName: 'Duration',
            sortable: true,
            width: 150
        },
        {
            field: 'screen',
            headerName: 'Screen',
            sortable: true,
            width: 300
        },
        {
            field: 'location',
            headerName: 'Location',
            sortable: true,
            width: 300
        }
    ]

    const analyticsColumns = [
        {
            field: 'date',
            headerName: 'Date',
            sortable: true,
            width: 150
        },
        {
            field: 'uniqueUsers',
            headerName: 'Unique Users',
            sortable: true,
            width: 150
        },
        {
            field: 'avgTime',
            headerName: 'Avg Session download time',
            sortable: true,
            width: 200
        },
        {
            field: 'maxTime',
            headerName: 'Max time',
            sortable: true,
            width: 150
        },
        {
            field: 'minTime',
            headerName: 'Min time',
            sortable: true,
            width: 150
        }
    ]

    const groupBy = function(xs, key) {
        return xs.reduce(function(rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };

    const getAnalytics = () => {
        let groupByDate = groupBy(data, 'date');
        let analytics = [];
        let i = 0;
        for (var date in groupByDate) {
            let items = groupByDate[date];
            let obj = {
                'id': i,
                'date': date,
                'items': items,
                'uniqueUsers': new Set(items.map(item => item.userId)).size,
                'minTime': Math.min(...items.map(item => item.duration)),
                'maxTime': Math.max(...items.map(item => item.duration)),
                'avgTime': items.length > 0 ? Number(items.map(item => item.duration).reduce((a, b) => a + b, 0) / items.length).toFixed(2) : 0
            }
            analytics.push(obj);
            i++;
        }

        return analytics;
    }

    const onRowClick = async (value) => {
        setDetailByDate(value.row.items);
        setDetailDate(value.row.date);
        setOpenModal(true);
    }
    
    const closeModal = () => {
        setOpenModal(false);
    }

    return (
        <div>
            <div>
                <div style={{ textAlign: 'center' }}> TRACKING DOWNLOAD SESSION TIME </div>
                <br></br>
                <div style={{ height: 500, width: '100%' }} className="d-grid gap-2 mt-3 justify-center">
                    <DataGrid
                        rows={data}
                        columns={columns}
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'startTime', sort: 'desc' }],
                            },
                        }}
                        slots={{
                            toolbar: CustomToolbar,
                        }} />
                </div>
                <br></br>
            </div>
            
            
            <div>
                <div style={{ textAlign: 'center' }}> ANALYTICS TRACKING SESSION DOWNLOAD TIME </div>
                <br></br>
                <div style={{ height: 500, width: '100%' }} className="d-grid gap-2 mt-3 justify-center">
                    <DataGrid
                        rows={getAnalytics()}
                        columns={analyticsColumns}
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'date', sort: 'desc' }],
                            },
                        }}
                        onRowClick={onRowClick}
                        slots={{
                            toolbar: CustomToolbar,
                        }}
                        />
                </div>
                <br></br>
            </div>
            
            <div className="justify-center">
                <Modal open={openModal}
                        onClose={closeModal}
                        aria-labelledby="Detail"
                        aria-describedby="modal-modal-description">
                    <Box sx={{ ...style, width: '90%', height: '90%' }}>
                        <h3 className="justify-center"> TRACKING DOWNLOAD SESSION TIME ON <>{detailDate}</> </h3>

                        <DataGrid 
                            rows={detailByDate} 
                            columns={columns} 
                            initialState={{
                            sorting: {
                                sortModel: [{ field: 'startTime', sort: 'desc' }],
                            },
                        }} />
                    </Box>
                </Modal>
            </div>
        </div>
    );
};

export default TrackingSessionDownloadTime;
