// import i18next from 'i18next';
// import { initReactI18next } from 'react-i18next';
// // "Inline" English and Arabic translations.
// // We can localize to any language and any number of languages.
// const resources = {
//   en: {
//     translation: {
//       redeem_now: 'Redeem Now',
//     },
//   },
//   cn: {
//     translation: {
//       redeem_now: '立即兑换',
//     },
//   },
// };
// i18next.use(initReactI18next).init({
//   resources,
//   lng: 'en',
//   interpolation: {
//     escapeValue: false,
//   },
// });
// export default i18next;
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18next
  .use(initReactI18next)
  .use(LanguageDetector) // Registering the detection plugin
  .use(HttpApi) // Registering the back-end plugin
  .init({
    // Remove resources from here
    // lng: 'en',
    // Allows "en-US" and "en-UK" to be implcitly supported when "en"
    // is supported
    // nonExplicitSupportedLngs: true,
    // // fallbackLng: 'en',
    // fallbackLng: {
    //   'en-US': ['en'],
    //   // 'zh-Hant': ['zh-Hans', 'en'],
    //   // es: ['fr'],
    //   default: ['en'],
    supportedLngs: ['en', 'cn', 'fr', 'nl', 'de'],
    fallbackLng: 'en',
    // },
    interpolation: {
      escapeValue: false,
    },
    debug: process.env.NODE_ENV === 'development',
  });
export default i18next;
