import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
// import axios from 'axios';
import { Base64 } from 'js-base64';
import { useTranslation } from 'react-i18next';
// import CircularProgress from '@material-ui/core/CircularProgress';

// import { getCompanyName, getDuration } from '../../utils/company';

const useStyles = makeStyles({
  root: {
    // minWidth: '340px',
    borderTop: '9px solid #C4008F',
    wordBreak: 'normal',
    marginBottom: '10px',
  },
  greetings: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '25px',
    lineHeight: '29px',
    color: '#253871',
  },
  email: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '21px',
  },
  text: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#484848',
    wordBreak: 'normal',
  },
});

export default function CustomerDetails({
  history,
  planSelected,
  lifetime,
  data,
  color,
  email,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  //   console.log('custdetails', history);
  //   const [isLoading, setIsLoading] = useState(true);
  //   const [data, setData] = useState({});
  //   const { color } = history.location.state;
  //   const { email } = history.location.state.userDetails;

  //   useEffect(() => {
  //     const token = localStorage.getItem('token');
  //     let unmounted = false;
  //     //headers
  //     const config = {
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'Access-Control-Allow-Origin':
  //           'https://' + window.location.host,
  //         Authorization: `Bearer ${token}`,
  //       },
  //     };

  //     // Request body
  //     const body = JSON.stringify({
  //       batch: batch,
  //       company: company,
  //       store: company,
  //     });

  //     axios
  //       .post(server_url + '/ecommerce/get_details', body, config)
  //       .then((res) => {
  //         // console.log('memdetails', res);
  //         if (!unmounted) {
  //           setData(res.data);
  //           setIsLoading(false);
  //         }
  //         // console.log('memdata', data);
  //       })
  //       .catch((err) => {
  //         // console.log(err);
  //         if (!unmounted) {
  //           console.error(err);
  //           setIsLoading(false);
  //         }
  //       });
  //     return () => {
  //       unmounted = true;
  //     };
  //   }, []);

  return (
    <>
      <Card
        className={classes.root}
        style={{ borderTop: `9px solid ${color}` }}
      >
        <CardContent>
          <p className={classes.greetings} style={{ color: color }}>
            {t('welcome')}
          </p>
          <p className={classes.email}>{Base64.decode(email)}</p>
          <hr></hr>
          <p>
            <strong>{t('am_points')}:</strong>
            {' ' + data.points}
          </p>
          <div>
            <div>
              <strong>{t('member_since')}:</strong>
              {' ' + data.member_since}
            </div>
            <div>
              <strong>{t('current_expiry')}:</strong>
              {' ' + data.current_expiry}
            </div>
            {/* <div>
              <strong>Expire after Purchase:</strong>{' '}
              {planSelected ? data.current_expiry + 1 : 'N/A'}
            </div> */}
          </div>
          <p className={classes.text}>
            {t('download_am_text_p1')} <strong>AmDTx</strong>{' '}
            {t('download_am_text_p2')}
          </p>
          <p className={classes.text}>{t('am_support')}</p>
          {/* <p className={classes.text}>
              <b>Congratulations!</b>
            </p>
            <p className={classes.text}>
              Your <b>Am Mindfulness</b> account was created on
              <strong style={{ color: color }}>
                {' ' + data.member_since + ' '}
              </strong>{' '}
              (UTC).
            </p>
            {lifetime ? (
              <div className={classes.text}>
                Because of <b>{getCompanyName(company)}</b>, you have
                a{' '}
                <strong style={{ color: color }}>
                  lifetime access
                </strong>{' '}
                to Am.
              </div>
            ) : (
              <div className={classes.text}>
                Because of <b>{getCompanyName(company)}</b>, you have
                received {getDuration(company)} of free premium
                access.
              </div>
            )}
            {!lifetime && (
              <div className={classes.text}>
                At present, your premium access will expire on{' '}
                <strong style={{ color: color }}>
                  {data.current_expiry}
                </strong>{' '}
                (UTC).
              </div>
            )} */}
        </CardContent>
      </Card>
    </>
  );
}
