import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
import { Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next';
import { TextField, InputAdornment } from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns';

import CustomButton from '../custom-button/custom-button';
import { returnErrorMessage } from '../../utils/company';
import {
  login,
  openGoogleLogin,
  getGscPlanDetails,
} from '../../redux/actions/authActions';
import { clearErrors } from '../../redux/actions/errorActions';
// import { getCompanyTheme } from '../../utils/company';
import { ReactComponent as GoogleIcon } from '../../assets/google-icon.svg';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0px',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    // marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  // instruction: {
  //   width: '222px',
  //   fontFamily: 'Roboto',
  //   fontStyle: 'normal',
  //   fontWeight: 'bold',
  //   fontSize: '25px',
  //   lineHeight: '35px',
  //   display: 'flex',
  //   alignItems: 'center',
  //   color: '#484848',
  //   marginBottom: '40px',
  // },
  assistedText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '10px',
    lineHeight: '12px',
    color: 'rgba(0, 0, 0, 0.6)',
    marginLeft: '14px',
  },
  alertText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#484848',
  },
  visibilityIcon: {
    cursor: 'pointer',
  },
}));

const SignIn = ({
  color,
  isAuthenticated,
  login,
  error,
  clearErrors,
  isLoading,
  sid = false,
  group = false,
  hideGoogle = false,
  getGscPlanDetails,
}) => {
  const match = useRouteMatch();
  const sm = useMediaQuery('(max-width: 430px)');
  const reverseButton = sm
    ? { flexDirection: 'column-reverse', marginTop: '39px' }
    : { marginTop: '39px' };
  const classes = useStyles({ sm });
  const { company } = match.params;
  // const color = getCompanyTheme(company);
  // const coloredLine = color && { border: `4px solid ${color}` };

  const [email, setEmail] = useState('');
  const [emailHelper, setEmailHelper] = useState('');

  const [password, setPassword] = useState('');
  const [passwordHelper, setPasswordHelper] = useState('');

  const [passwordVisible, setPasswordVisible] = useState(false);

  const [gsc, showGsc] = useState(false);
  const [planId, setPlanID] = useState('');
  const [selectedDate, handleDateChange] = useState(new Date());

  const [open, setOpen] = useState(true);
  const [modalError, setModalError] = useState(null);
  const { t } = useTranslation();

  const handleChange = (event) => {
    let valid;

    switch (event.target.id) {
      case 'email':
        setEmail(event.target.value);
        valid = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
          event.target.value,
        );

        if (!valid) {
          setEmailHelper(t('invalid_email'));
        } else {
          setEmailHelper('');
        }
        break;
      case 'password':
        setPassword(event.target.value);
        valid = /^.{8,}$/.test(event.target.value);

        if (!valid) {
          setPasswordHelper(t('pw_validation'));
        } else {
          setPasswordHelper('');
        }
        break;
      case 'planId':
        setPlanID(event.target.value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // setLoading(true);
    // Create user object
    const userDetails = {
      email:
        company === 'synaptitude'
          ? localStorage.getItem('email')
          : email,
      password,
      clientVersion: '1.8.13',
    };
    // Attempt to signin
    if (gsc) {
      const planDetails = {
        planMemberId: planId,
        participantBirthDate: format(selectedDate, 'MM-dd-yyyy'),
      };
      getGscPlanDetails(planDetails, userDetails, company, 'login');
      // login(
      //   userDetails,
      //   company,
      //   sid,
      //   group,
      //   planId,
      //   null,
      //   planDetails,
      // );
    } else {
      login(userDetails, company, sid, group);
    }
  };

  // const handleToggle = useCallback(() => {
  //   // Clear errors
  //   clearErrors();
  //   setOpen(!open);
  // }, [clearErrors, open]);
  const handleClose = () => {
    setOpen(false);
    clearErrors();
  };

  const handleOpen = () => {
    setOpen(true);
    // clearErrors();
    return open;
  };
  useEffect(() => {
    // Check for register error
    if (error.id === 'LOGIN_FAIL') {
      // console.log('err', error);
      setModalError(t(error.msg));
      // console.log(error.msg);
      // console.log(gsc);
      if (error.msg === 'GSCNotValid') {
        showGsc(true);
      }
    } else {
      setModalError(null);
      // showGsc(false);
    }

    // If authenticated, close modal
    // if (open) {
    //   handleToggle();
    // }
  }, [error, isAuthenticated, modalError, showGsc]);
  // console.log('loading', isLoading);

  const modal = (
    <Dialog open={handleOpen} onClose={handleClose}>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          className={classes.alertText}
        >
          {returnErrorMessage(modalError)}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={handleClose} backgroundColor={color}>
          {t('continue')}
        </CustomButton>
      </DialogActions>
    </Dialog>
  );

  let terms = `https://www.mobiointeractive.com/terms-of-use-${localStorage.getItem(
    'i18nextLng',
  )}`;

  return (
    <Container
      component="main"
      maxWidth="sm"
      className={classes.root}
    >
      {/* <hr style={coloredLine}></hr>
      <div className={classes.instruction}>
        Fill the form and get Premium discounts on Am.
      </div> */}
      <form className={classes.form} onSubmit={handleSubmit}>
        <Grid container spacing={4}>
          {company === 'synaptitude' ? (
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label={t('email')}
                name="email"
                value={localStorage.getItem('email')}
                InputProps={{
                  readOnly: true,
                }}
                variant="outlined"
              />
            </Grid>
          ) : (
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label={t('email')}
                name="email"
                autoComplete="email"
                value={email}
                onChange={handleChange}
                error={emailHelper.length !== 0}
                helperText={emailHelper}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              name="password"
              label={t('password')}
              type={passwordVisible ? 'text' : 'password'}
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={handleChange}
              error={passwordHelper.length !== 0}
              helperText={passwordHelper}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {passwordVisible ? (
                      <VisibilityOffIcon
                        className={classes.visibilityIcon}
                        onClick={() => setPasswordVisible(false)}
                      />
                    ) : (
                      <VisibilityIcon
                        className={classes.visibilityIcon}
                        onClick={() => setPasswordVisible(true)}
                      />
                    )}
                  </InputAdornment>
                ),
              }}
            />
            {/* <span className={classes.assistedText}>
              {t('agree_terms')}
            </span> */}
          </Grid>
          {gsc ? (
            <>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="planId"
                  label={t('plan_id')}
                  name="planId"
                  autoComplete="planId"
                  value={planId}
                  onChange={handleChange}
                  // error={emailHelper.length !== 0}
                  // helperText={emailHelper}
                />
              </Grid>
              <Grid item xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    autoOk
                    variant="inline"
                    required
                    fullWidth
                    inputVariant="outlined"
                    label={t('dob')}
                    format="MM-dd-yyyy"
                    value={selectedDate}
                    InputAdornmentProps={{ position: 'start' }}
                    onChange={(date) => handleDateChange(date)}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </>
          ) : null}
          <span className={classes.assistedText}>
            {t('agree_terms_p1')}
            <a target="_blank" rel="noopener noreferrer" href={terms}>
              {t('agree_terms_p2')}
            </a>
          </span>
        </Grid>
        <Grid
          container
          justifyContent="space-between"
          style={reverseButton}
        >
          {hideGoogle ? null : (
            <Grid item>
              <CustomButton
                backgroundColor="#FFFFFF"
                onClick={() => openGoogleLogin(company)}
                textColor="#484848"
                style={{ marginBottom: '14px' }}
              >
                <GoogleIcon style={{ marginRight: '9px' }} />
                {t('continue_google')}
              </CustomButton>
            </Grid>
          )}
          <Grid item>
            <CustomButton
              type="submit"
              backgroundColor={color}
              style={{ width: '140px', marginBottom: '14px' }}
            >
              {isLoading ? (
                <CircularProgress size={25} color="white" />
              ) : (
                t('login')
              )}
            </CustomButton>
          </Grid>
        </Grid>
        {/* <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="#" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid> */}
      </form>
      {modalError ? modal : null}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  error: state.error,
  isLoading: state.auth.isLoading,
});

export default connect(mapStateToProps, {
  login,
  clearErrors,
  getGscPlanDetails,
})(SignIn);
