import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { getLogo } from '../../utils/company';

const useStyles = makeStyles((theme) => ({
  imgContainer: {
    // height: '60px',
    width: '90px',
  },
}));

const CompanyLogo = ({ company, style }) => {
  const classes = useStyles();

  return (
    <img
      className={style || classes.imgContainer}
      src={getLogo(company)}
      alt="partner-logo"
      onError={(event) => (event.target.style.display = 'none')}
    />
  );
};

export default CompanyLogo;
