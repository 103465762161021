import React, { useEffect } from 'react';
import { Grid, makeStyles, useMediaQuery } from '@material-ui/core';
// import { makeStyles } from '@material-ui/core/styles';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Header from '../../../components/header/header';
import Footer from '../../../components/footer/footer';
import CompanyDetailsFlex from '../../../components/company-details-flex/company-details-flex';
import {
  getCompanyTheme,
  getBackgroundImg,
  getLogo,
} from '../../../utils/company';
import { BASE_CDN_URL } from '../../../utils/constants';
import {
  sendAmplitudeData,
  detectPlatform,
} from '../../../utils/amplitude';
import ActivateButton from '../../../components/activate-button/activate-button';
import SocialIcons from '../../../components/social-icons/social-icons';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: 'calc(100vh - 70px - 47px)',
    overflow: 'auto',
  },
  mainContainer: {
    height: '100%',
  },
  leftBackgroundImg: {
    backgroundImage: `url(${BASE_CDN_URL + '/grey-bg.png'})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
  },
  rightBackgroundImg: {
    backgroundImage: `url(${getBackgroundImg('mindfuljunkie')})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  leftContainer: {
    marginLeft: '41px',
    marginRight: '56px',
    marginTop: '54px',
    marginBottom: '20px',
  },
  textContainer: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#484848',
    marginTop: '43px',
  },
  storeContainer: {
    height: 'calc(100vh - 119px)',
  },
  mobButtonContainer: {
    marginTop: '40px',
    paddingBottom: '80px',
  },
  imgContainer: {
    height: '49px',
    width: '47px',
  },
}));

function LandingPage() {
  const sm = useMediaQuery('(max-width: 960px)');
  const leftPaneSize = sm ? 12 : 5;
  const classes = useStyles({ sm });
  const match = useRouteMatch();
  const history = useHistory();
  const { company } = match.params;
  const { t } = useTranslation();
  const platform = detectPlatform();

  useEffect(() => {
    sendAmplitudeData('landing_page_viewed', {
      company: company,
      device_platform: detectPlatform(),
      time: new Date().toISOString(),
      origin: window.location.origin,
    });

    localStorage.setItem('sid', null);
    localStorage.setItem('group', null);
    localStorage.setItem('phase', null);
    localStorage.setItem('store', null);
  });

  return (
    <React.Fragment>
      <Header />
      <div className={classes.root}>
        <Grid container className={classes.mainContainer}>
          <Grid
            item
            xs={leftPaneSize}
            className={classes.leftBackgroundImg}
          >
            <div className={classes.leftContainer}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href=" http://www.mindfuljunkie.com/"
              >
                <img
                  className={classes.imgContainer}
                  src={getLogo(company)}
                  alt="partner-logo"
                />
              </a>
              <CompanyDetailsFlex
                slogan="MINDFULNESS FOR FIRST RESPONDERS"
                companyName={company}
              >
                <p>
                  Delivered via AmDTx, the only mindfulness app that
                  has outperformed placebo in scientific studies
                  examining attentional control, endurance, mental
                  fitness and stress resilience.
                </p>
                <p>
                  Join the engaging experience with your expert guide
                  Gina Rollo White and track the impact of a robust
                  contemplative practice on your mind and body.
                </p>
                <div>
                  <b>{t('am_love')}</b>
                </div>
              </CompanyDetailsFlex>
              <ActivateButton
                label={t('preferred_pricing')}
                backgroundColor={getCompanyTheme(company)}
                sm={sm}
                onClick={() => history.push(`/${company}/signup`)}
              />
            </div>
            <SocialIcons
              sm={sm}
              platform={platform}
              company={company}
            />
          </Grid>
          {sm ? null : (
            <Grid
              item
              xs={7}
              className={classes.rightBackgroundImg}
            ></Grid>
          )}
        </Grid>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default LandingPage;
