import React, { useEffect } from 'react';
import { Grid, makeStyles, useMediaQuery } from '@material-ui/core';
// import { makeStyles } from '@material-ui/core/styles';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CompanyLogo from '../../../components/company-logo/company-logo';
import Header from '../../../components/header/header';
import Footer from '../../../components/footer/footer';
import CompanyDetailsFlex from '../../../components/company-details-flex/company-details-flex';
import {
  getCompanyTheme,
  getBackgroundImg,
  getLogo,
} from '../../../utils/company';
import { BASE_CDN_URL } from '../../../utils/constants';
import {
  sendAmplitudeData,
  detectPlatform,
} from '../../../utils/amplitude';
import ActivateButton from '../../../components/activate-button/activate-button';
import SocialIcons from '../../../components/social-icons/social-icons';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: 'calc(100vh - 70px - 47px)',
    overflow: 'auto',
  },
  mainContainer: {
    height: '100%',
  },
  leftBackgroundImg: {
    backgroundImage: `url(${BASE_CDN_URL + '/grey-bg.png'})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
  },
  rightBackgroundImg: {
    backgroundImage: `url(${getBackgroundImg('synaptichacks')})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  leftContainer: {
    marginLeft: '41px',
    marginRight: '56px',
    marginTop: '54px',
    marginBottom: '20px',
  },
  textContainer: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#484848',
    marginTop: '43px',
  },
  storeContainer: {
    height: 'calc(100vh - 119px)',
  },
  imgContainer: {
    height: '100px',
    width: '150px',
  },
}));

function LandingPage() {
  const sm = useMediaQuery('(max-width: 960px)');
  const leftPaneSize = sm ? 12 : 5;
  const classes = useStyles({ sm });
  const match = useRouteMatch();
  const history = useHistory();
  const { company } = match.params;
  const { t } = useTranslation();
  const platform = detectPlatform();

  useEffect(() => {
    sendAmplitudeData('landing_page_viewed', {
      company: company,
      device_platform: detectPlatform(),
      time: new Date().toISOString(),
      origin: window.location.origin,
    });

    localStorage.setItem('sid', null);
    localStorage.setItem('group', null);
    localStorage.setItem('phase', null);
    localStorage.setItem('store', null);
  });

  return (
    <React.Fragment>
      <Header />
      <div className={classes.root}>
        <Grid container className={classes.mainContainer}>
          <Grid
            item
            xs={leftPaneSize}
            className={classes.leftBackgroundImg}
          >
            <div className={classes.leftContainer}>
              <CompanyLogo company={company} />
              <CompanyDetailsFlex
                slogan={
                  'EMPOWERING YOUTH TO ENGAGE IN BRAIN-RELATED FIELDS'
                }
                companyName={company}
              >
                <p>
                  {'Mobio Interactive presents'} <b>AmDTx</b>
                  {
                    ', a clinically effective way to begin your personalised mindfulness journey.'
                  }
                </p>
                <p>
                  {'Only'} <b>AmDTx</b>{' '}
                  {
                    'has outperformed placebo in clinical trials examining attentional control, endurance, mental fitness and stress resilience.'
                  }
                </p>
                <div>
                  {
                    'Take charge of your mind. Maximise your potential.'
                  }
                </div>
                <p>
                  <b>
                    {
                      'Simply Neuroscience is proud to provide you with a special offer to this one-of-a-kind mindfulness experience:'
                    }{' '}
                  </b>
                </p>
                <ul>
                  <li>
                    An 80% discount on AmDTx for a one-year
                    subscription. (
                    <i>Offer valid until August 31st, 2021</i>)
                  </li>
                </ul>
                <p>
                  Simply Neuroscience is an international, student-led
                  nonprofit organization dedicated to fostering
                  students' interdisciplinary interests in the brain,
                  specifically through neuroscience and psychology
                  education, outreach, and awareness. Our aim is to
                  empower youth to engage in brain-related fields
                  through projects and initiatives on local through
                  global scales.
                </p>
              </CompanyDetailsFlex>
              <ActivateButton
                label={t('redeem_now')}
                backgroundColor={getCompanyTheme(company)}
                sm={sm}
                onClick={() => history.push(`/${company}/signup`)}
              />
            </div>
            <SocialIcons
              sm={sm}
              platform={platform}
              company={company}
            />
          </Grid>
          {sm ? null : (
            <Grid
              item
              xs={7}
              className={classes.rightBackgroundImg}
            ></Grid>
          )}
        </Grid>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default LandingPage;
