import React from 'react';
import { DataGrid } from '@mui/x-data-grid';

const NeuroglymseAnalytics = ({ data, CustomToolbar }) => {
    const columns = [
        {
            field: 'month',
            headerName: 'Month',
            sortable: true,
            width: 200,
            valueGetter: (value, row) => {
                let month = value.row.month.replace('-', '');
                return month.substring(0, 6)
            },
        },
        {
            field: 'uniqueNumber',
            headerName: 'Number',
            sortable: true,
            width: 150
        },
        {
            field: 'userIds',
            headerName: 'User IDs',
            sortable: true,
            width: 1000
        }
    ]


    return (
        <div>
            <div style={{ textAlign: 'center' }}> TRACKING UUIDs </div>
            <br></br>
            <div style={{ height: 500, width: '100%' }} className="d-grid gap-2 mt-3 justify-center">
                <DataGrid
                    rows={data}
                    columns={columns}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'month', sort: 'desc' }],
                        },
                    }}
                    slots={{
                        toolbar: CustomToolbar,
                    }} />
            </div>
            <br></br>
        </div>
    );
};

export default NeuroglymseAnalytics;
