import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  backgroundColorStyle: {
    background: (props) =>
      `linear-gradient(45deg, ${props.backgroundColor} 30%, ${props.backgroundColor} 90%)`,
  },
  textColorStyle: {
    color: (props) => props.textColor || '#FFFFFF',
  },
}));

const CustomButton = ({ children, ...props }) => {
  const { backgroundColorStyle, textColorStyle } = useStyles(props);
  const { backgroundColor, textColor, ...rest } = props;

  return (
    <>
      <Button
        variant="contained"
        style={{ textTransform: 'none' }}
        className={`${backgroundColorStyle} ${textColorStyle}`}
        {...rest}
      >
        {children}
      </Button>
    </>
  );
};

export default CustomButton;
