import React, { useEffect, useState } from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import CompanyLogo from '../../../components/company-logo/company-logo';
import Header from '../../../components/header/header';
import Footer from '../../../components/footer/footer';
import CustomerDetails from '../../../components/customer-details/customer-details';
import MemberDetailsFlex from '../../../components/member-details/member-details-flex';
import { getLogo } from '../../../utils/company';
// import PricingCard from '../../../components/pricing-card/pricing-card';
// import CustomButton from '../../../components/custom-button/custom-button';

import { BASE_CDN_URL } from '../../../utils/constants';
import {
  sendAmplitudeData,
  detectPlatform,
} from '../../../utils/amplitude';
import PricingCard from '../../../components/pricing-card/pricing-card';

// import './homepage.styles.scss';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // height: 'calc(100vh - 70px - 47px)',
    // height: 'auto',
    overflow: 'auto',
  },
  rootMob: {
    flexGrow: 1,
    overflow: 'auto',
  },
  mainContainer: {
    height: '100%',
    backgroundImage: `url(${BASE_CDN_URL + '/grey-bg.png'})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
  },
  leftBackgroundImg: {
    backgroundImage: `url(${BASE_CDN_URL + '/grey-bg.png'})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
  },
  innerContainer: {
    marginTop: '70px',
    marginLeft: '146px',
    marginRight: '146px',
  },
  innerContainerMob: {
    marginTop: '35px',
    marginLeft: '25px',
    marginRight: '25px',
  },
  imgContainer: {
    height: '64px',
    width: '47px',
    marginBottom: '30px',
  },
  link1: {
    width: '135px',
    height: '41px',
  },
  link2: {
    width: '156px',
    height: '60px',
  },
  linkWrapper: {
    display: 'flex',
    alignSelf: 'flex-end',
    alignItems: 'center',
    marginLeft: '41px',
    marginBottom: '31px',
  },
  linkWrapperMob: {
    display: 'flex',
    alignSelf: 'flex-end',
    alignItems: 'center',
    marginLeft: '20px',
    marginBottom: '31px',
    flexDirection: 'column',
  },
  priceCardWrapper: {
    marginTop: '20px',
    display: 'flex',
    marginBottom: '40px',
  },
  priceCardWrapperMob: {
    marginTop: '20px',
    marginBottom: '80px',
    // display: 'flex',
    // flexDirection: 'column',
  },
}));

function CartPage(props) {
  const xs = useMediaQuery('(max-width: 631px)');
  const classes = useStyles({ xs });
  // const customMargin= xs ? {classes.innerContainerMob} : {classes.innerContainer}
  const history = useHistory();
  // console.log('cartpage', history);
  // const { company } = history.location.state;
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});
  const { company, batch, color } = history.location.state;
  const { server_url, email } = history.location.state.userDetails;

  // const price = data.price_details.eur.year / 100;

  useEffect(() => {
    const token = localStorage.getItem('token');
    let unmounted = false;
    //headers
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin':
          'https://' + window.location.host,
        Authorization: `Bearer ${token}`,
      },
    };

    // Request body
    const body = JSON.stringify({
      batch: batch,
      company: company,
      store: company,
      discount: 25,
    });

    axios
      .post(server_url + '/ecommerce/get_details', body, config)
      .then((res) => {
        // console.log('memdetails', res);
        if (!unmounted) {
          setData(res.data);
          setIsLoading(false);
        }
        // console.log('memdata', data);
      })
      .catch((err) => {
        // console.log(err);
        if (!unmounted) {
          console.error(err);
          setIsLoading(false);
        }
      });

    sendAmplitudeData('cart_page_viewed', {
      company: company,
      device_platform: detectPlatform(),
      time: new Date().toISOString(),
      origin: window.location.origin,
    });

    return () => {
      unmounted = true;
    };
  }, []);

  return (
    <React.Fragment>
      <Header />
      <div className={xs ? classes.rootMob : classes.root}>
        <Grid container className={classes.mainContainer}>
          <Grid item xs={12}>
            <div
              className={
                xs
                  ? classes.innerContainerMob
                  : classes.innerContainer
              }
            >
              <CompanyLogo company={company} />
              {isLoading ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <CircularProgress
                    size={100}
                    style={{ color: color }}
                  />
                </div>
              ) : (
                <Grid>
                  <MemberDetailsFlex history={history} data={data} />
                  {/* <CustomerDetails
                    history={history}
                    data={data}
                    color={color}
                    email={email}
                  /> */}
                  <div
                    className={
                      xs
                        ? classes.priceCardWrapperMob
                        : classes.priceCardWrapper
                    }
                  >
                    <PricingCard
                      title="YEARLY"
                      price={data.price_details.cad.year / 100}
                      priceId={data.price_details.cad.year_price_id}
                      saving={data.price_details.cad.savings_year.toFixed(
                        2,
                      )}
                      discount="25%"
                      currency="C$"
                      userDetails={data}
                      email={email}
                      serverUrl={server_url}
                      color={color}
                    />
                    <PricingCard
                      title="LIFETIME"
                      price={data.price_details.cad.lifetime / 100}
                      priceId={
                        data.price_details.cad.lifetime_price_id
                      }
                      saving={data.price_details.cad.savings_lifetime.toFixed(
                        2,
                      )}
                      discount="25%"
                      currency="C$"
                      userDetails={data}
                      email={email}
                      serverUrl={server_url}
                      color={color}
                    />
                  </div>
                </Grid>
              )}
            </div>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default CartPage;
