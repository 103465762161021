import React, { useEffect, useState } from 'react';
import { Grid, makeStyles, useMediaQuery } from '@material-ui/core';
// import { makeStyles } from '@material-ui/core/styles';
import { useRouteMatch, useHistory } from 'react-router-dom';
import CompanyLogo from '../../../components/company-logo/company-logo';
import Header from '../../../components/header/header';
import Footer from '../../../components/footer/footer';
import CompanyDetailsFlex from '../../../components/company-details-flex/company-details-flex';
import {
  getCompanyTheme,
  getBackgroundImg,
  getLogo,
} from '../../../utils/company';
import { BASE_CDN_URL } from '../../../utils/constants';
import {
  sendAmplitudeData,
  detectPlatform,
} from '../../../utils/amplitude';
import ActivateButton from '../../../components/activate-button/activate-button';
import SocialIcons from '../../../components/social-icons/social-icons';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: 'calc(100vh - 70px - 47px)',
    overflow: 'auto',
  },
  mainContainer: {
    height: '100%',
  },
  leftBackgroundImg: {
    backgroundImage: `url(${BASE_CDN_URL + '/grey-bg.png'})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
  },
  rightBackgroundImg: {
    backgroundImage: `url(${getBackgroundImg('synaptitude')})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  leftContainer: {
    marginLeft: '41px',
    marginRight: '56px',
    marginTop: '54px',
    marginBottom: '20px',
  },
  textContainer: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#484848',
    marginTop: '43px',
  },
  storeContainer: {
    height: 'calc(100vh - 119px)',
  },
  imgContainer: {
    height: '28px',
    width: '153px',
  },
}));

function LandingPage() {
  const [show, setShow] = React.useState(false);

  const sm = useMediaQuery('(max-width: 960px)');
  const leftPaneSize = sm ? 12 : 5;
  const classes = useStyles({ sm });
  const match = useRouteMatch();
  const history = useHistory();
  const { company } = match.params;
  const params = new URLSearchParams(history.location.search);
  const sid = params.get('sid');
  const email = params.get('email');
  const origin = params.get('origin');
  const platform = detectPlatform();

  useEffect(() => {
    sendAmplitudeData('landing_page_viewed', {
      company: company,
      device_platform: detectPlatform(),
      time: new Date().toISOString(),
      origin: window.location.origin,
    });

    localStorage.setItem('sid', sid);
    localStorage.setItem('email', email);
    localStorage.setItem('origin', origin);
    localStorage.setItem('group', null);
    localStorage.setItem('phase', null);
    localStorage.setItem('store', null);

    setShow(true);
  }, [show]);

  if (!show) return null;

  return (
    <React.Fragment>
      <Header />
      <div className={classes.root}>
        <Grid container className={classes.mainContainer}>
          <Grid
            item
            xs={leftPaneSize}
            className={classes.leftBackgroundImg}
          >
            <div className={classes.leftContainer}>
              <CompanyLogo company={company} />
              <CompanyDetailsFlex
                slogan="Understand your brain. Maximize it with science."
                companyName={company}
              >
                <p>
                  Synaptitude Brain Health and Mobio Interactive
                  present Am Mindfulness, a clinically effective guide
                  for your personalized mindfulness journey.
                </p>
                <p>
                  Mindfulness is a method of Stress Management, one of
                  five pillars for your cognitive wellness that
                  creates clarity today and reduces your risk of
                  decline in the future.
                </p>
                <p>
                  The Am Mindfulness app objectively measures your
                  current physiological stress using just your phone.
                  You'll explore a variety of step-by-step Mindfulness
                  Journeys to build your roadmap to maintaining a calm
                  mind. You'll also learn how small touches of goal
                  setting, personal mindfulness, and stress awareness
                  create a simple routine for daily cognitive
                  maintenance.
                </p>
                <p>
                  Empower your healthiest self: knowledge of how to
                  manage stress today is the confidence to keep up
                  with the demands of tomorrow.
                </p>
              </CompanyDetailsFlex>
              {sid !== null &&
              sid.length === 20 &&
              email !== null &&
              (origin === 'synaptitude_dev' ||
                origin === 'synaptitude_qa' ||
                origin === 'synaptitude_prod' ||
                origin === 'synaptitude_prod_test') ? (
                <ActivateButton
                  label="Get Started"
                  backgroundColor={getCompanyTheme(company)}
                  sm={sm}
                  onClick={() => history.push(`/${company}/signup`)}
                />
              ) : null}
            </div>
            <SocialIcons
              sm={sm}
              platform={platform}
              company={company}
            />
          </Grid>
          {sm ? null : (
            <Grid
              item
              xs={7}
              className={classes.rightBackgroundImg}
            ></Grid>
          )}
        </Grid>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default LandingPage;
