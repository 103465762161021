import React, { useEffect, useMemo, useState } from "react"
import { DataGrid } from '@mui/x-data-grid';
import Modal from '@mui/material/Modal';
import Box from "@mui/material/Box";

const TrackingLogin = ({ data, CustomToolbar }) => {

    const [openModal, setOpenModal] = useState(false);
    const [detailTrackingLoginsByDate, setDetailTrackingLoginsByDate] = useState(false);
    const [detailDate, setDetailDate] = useState('');

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };

    const columns = [
        {
            field: 'userId',
            headerName: 'USER UUID',
            sortable: true,
            width: 300
        },
        {
            field: 'startTime',
            headerName: 'Start time',
            sortable: true,
            width: 300
        },
        {
            field: 'successfulTime',
            headerName: 'Successful Time',
            sortable: true,
            width: 300
        },
        {
            field: 'offsetTime',
            headerName: 'Offset',
            sortable: true,
            width: 150
        },
        {
            field: 'duration',
            headerName: 'Duration',
            sortable: true,
            width: 150
        },
        {
            field: 'type',
            headerName: 'Method',
            sortable: true,
            width: 150
        },
        {
            field: 'location',
            headerName: 'Location',
            sortable: true,
            width: 150
        }
    ]

    const analyticsColumns = [
        {
            field: 'date',
            headerName: 'Date',
            sortable: true,
            width: 150
        },
        {
            field: 'email',
            headerName: 'Email',
            sortable: true,
            width: 100
        },
        {
            field: 'avgEmailTime',
            headerName: 'Avg Email login time',
            sortable: true,
            width: 150
        },
        {
            field: 'maxEmailTime',
            headerName: 'Max Email login time',
            sortable: true,
            width: 150
        },
        {
            field: 'minEmailTime',
            headerName: 'Min Email login time',
            sortable: true,
            width: 150
        },
        {
            field: 'google',
            headerName: 'Google',
            sortable: true,
            width: 100
        },
        {
            field: 'avgGoogleTime',
            headerName: 'Avg Google login time',
            sortable: true,
            width: 150
        },
        {
            field: 'maxGoogleTime',
            headerName: 'Max Google login time',
            sortable: true,
            width: 150
        },
        {
            field: 'minGoogleTime',
            headerName: 'Min Google login time',
            sortable: true,
            width: 150
        },
        {
            field: 'apple',
            headerName: 'Apple',
            sortable: true,
            width: 100
        },
        {
            field: 'avgAppleTime',
            headerName: 'Avg Apple login time',
            sortable: true,
            width: 150
        },
        {
            field: 'maxAppleTime',
            headerName: 'Max Apple login time',
            sortable: true,
            width: 150
        },
        {
            field: 'minAppleTime',
            headerName: 'Min Apple login time',
            sortable: true,
            width: 150
        }
    ]

    const groupBy = function(xs, key) {
        return xs.reduce(function(rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };

    const getAnalyticsTrackingLogin = (data) => {
        let groupByDate = groupBy(data, 'date');
        let analytics = [];
        let i = 0;
        for (var date in groupByDate) {
            let items = groupByDate[date];
            let durationEmails = items.filter(item => item.type == 'EMAIL').map(item => item.duration);
            let durationGoogles = items.filter(item => item.type == 'GOOGLE').map(item => item.duration);
            let durationApples = items.filter(item => item.type == 'APPLE').map(item => item.duration);
            
            let obj = {
                'id': i,
                'date': date,
                'items': items,
                'email': durationEmails.length,
                'avgEmailTime': durationEmails.length > 0 ? Number(durationEmails.reduce((a, b) => a + b, 0) / durationEmails.length).toFixed(2) : 0,
                'minEmailTime': durationEmails.length > 0 ? Math.min(...durationEmails) : 0,
                'maxEmailTime': durationEmails.length > 0 ? Math.max(...durationEmails) : 0,
                'google': durationGoogles.length,
                'avgGoogleTime': durationGoogles.length > 0 ? Number(durationGoogles.reduce((a, b) => a + b, 0) / durationGoogles.length).toFixed(2) : 0,
                'minGoogleTime': durationGoogles.length > 0 ? Math.min(...durationGoogles) : 0,
                'maxGoogleTime': durationGoogles.length > 0 ? Math.max(...durationGoogles) : 0,
                'apple': durationApples.length,
                'avgAppleTime': durationApples.length > 0 ? Number(durationApples.reduce((a, b) => a + b, 0) / durationApples.length).toFixed(2) : 0,
                'minAppleTime': durationApples.length > 0 ? Math.min(...durationApples) : 0,
                'maxAppleTime': durationApples.length > 0 ? Math.max(...durationApples) : 0
            }
            analytics.push(obj);
            i++;
        }
        return analytics;
    }

    const getAnalytics = () => {
        return getAnalyticsTrackingLogin(data);
    }

    const onRowClick = async (value) => {
        setDetailTrackingLoginsByDate(value.row.items);
        setDetailDate(value.row.date);
        setOpenModal(true);
    }
    
    const closeModal = () => {
        setOpenModal(false);
    }

    return (
        <div>
            <div>
                <div style={{ textAlign: 'center' }}> TRACKING LOGIN </div>
                <br></br>
                <div style={{ height: 500, width: '100%' }} className="d-grid gap-2 mt-3 justify-center">
                    <DataGrid
                        rows={data}
                        columns={columns}
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'startTime', sort: 'desc' }],
                            },
                        }}
                        slots={{
                            toolbar: CustomToolbar,
                        }} />
                </div>
                <br></br>
            </div>
            
            
            <div>
                <div style={{ textAlign: 'center' }}> ANALYTICS TRACKING LOGIN </div>
                <br></br>
                <div style={{ height: 500, width: '100%' }} className="d-grid gap-2 mt-3 justify-center">
                    <DataGrid
                        rows={getAnalytics()}
                        columns={analyticsColumns}
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'date', sort: 'desc' }],
                            },
                        }}
                        onRowClick={onRowClick}
                        slots={{
                            toolbar: CustomToolbar,
                        }}
                        />
                </div>
                <br></br>
            </div>
            
            <div className="justify-center">
                <Modal open={openModal}
                        onClose={closeModal}
                        aria-labelledby="Detail"
                        aria-describedby="modal-modal-description">
                    <Box sx={{ ...style, width: '90%', height: '90%' }}>
                        <h3 className="justify-center"> TRACKING LOGIN ON <>{detailDate}</> </h3>

                        <DataGrid 
                            rows={detailTrackingLoginsByDate} 
                            columns={columns} 
                            initialState={{
                            sorting: {
                                sortModel: [{ field: 'startTime', sort: 'desc' }],
                            },
                        }} />
                    </Box>
                </Modal>
            </div>
        </div>
    );
};

export default TrackingLogin;
