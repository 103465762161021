import React, { useEffect, useState } from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next';

import Header from '../../../components/header/header';
import Footer from '../../../components/footer/footer';
import CustomerDetails from '../../../components/customer-details/customer-details';
import { getLogo } from '../../../utils/company';
// import PricingCard from '../../../components/pricing-card/pricing-card';
// import CustomButton from '../../../components/custom-button/custom-button';

import { BASE_CDN_URL } from '../../../utils/constants';
import {
  sendAmplitudeData,
  detectPlatform,
} from '../../../utils/amplitude';
import { Base64 } from 'js-base64';
import StripeForm from '../../../components/stripe-form/stripe-form';
import CompanyLogo from '../../../components/company-logo/company-logo';

// import './homepage.styles.scss';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: 'calc(100vh - 70px - 47px)',
    overflow: 'auto',
  },
  mainContainer: {
    height: '100%',
    backgroundImage: `url(${BASE_CDN_URL + '/grey-bg.png'})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
  },
  leftBackgroundImg: {
    backgroundImage: `url(${BASE_CDN_URL + '/grey-bg.png'})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
  },
  innerContainer: {
    marginTop: '70px',
    marginLeft: '146px',
    marginRight: '146px',
  },
  innerContainerMob: {
    marginTop: '35px',
    marginLeft: '25px',
    marginRight: '25px',
  },
  imgContainer: {
    width: '90px',
    height: '37px',
    marginBottom: '30px',
  },
  link1: {
    width: '135px',
    height: '41px',
  },
  link2: {
    width: '156px',
    height: '60px',
  },
  linkWrapper: {
    display: 'flex',
    alignSelf: 'flex-end',
    alignItems: 'center',
    marginLeft: '41px',
    marginBottom: '31px',
  },
  linkWrapperMob: {
    display: 'flex',
    alignSelf: 'flex-end',
    alignItems: 'center',
    marginLeft: '20px',
    marginBottom: '31px',
    flexDirection: 'column',
  },
}));

function CheckoutPage(props) {
  const xs = useMediaQuery('(max-width: 631px)');
  const classes = useStyles({ xs });
  // const customMargin= xs ? {classes.innerContainerMob} : {classes.innerContainer}
  const history = useHistory();
  // console.log('checkoutpage', history);
  // const { company } = history.location.state;
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});
  // const { company, batch, color } = history.location.state;
  const {
    serverUrl,
    email,
    company,
    color,
    userDetails,
    priceId,
    planDuration,
  } = history.location.state;
  const { t } = useTranslation();

  useEffect(() => {
    const token = localStorage.getItem('token');
    // let unmounted = false;
    //headers
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin':
          'https://' + window.location.host,
        Authorization: `Bearer ${token}`,
      },
    };

    // Request body
    // const body = JSON.stringify({
    //   batch: batch,
    //   company: company,
    //   store: company,
    // });

    axios
      .get(
        serverUrl +
          `/ecommerce/createCustomer?email=${Base64.decode(email)}`,
        { data: {}, config },
      )
      .then((res) => {
        // console.log('memdetails', res.data);
        // if (!unmounted) {
        setData(res.data);
        setIsLoading(false);
        // }
        // console.log('custdata', data);
      })
      .catch((err) => {
        // console.log(err);
        // if (!unmounted) {
        console.error(err);
        setIsLoading(false);
        // }
      });

    sendAmplitudeData('checkout_page_viewed', {
      company: company,
      device_platform: detectPlatform(),
      time: new Date().toISOString(),
      origin: window.location.origin,
    });

    // return () => {
    //   unmounted = true;
    // };
  }, []);

  return (
    <React.Fragment>
      <Header en cn fr de />
      <div className={classes.root}>
        <Grid container className={classes.mainContainer}>
          <Grid item xs={12}>
            <div
              className={
                xs
                  ? classes.innerContainerMob
                  : classes.innerContainer
              }
            >
              <CompanyLogo company={company} />
              {isLoading ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <CircularProgress
                    size={100}
                    style={{ color: color }}
                  />
                </div>
              ) : (
                <Grid>
                  <CustomerDetails
                    history={history}
                    data={userDetails}
                    color={color}
                    email={email}
                  />
                  {/* <PricingCard title="YEARLY" /> */}
                  <StripeForm
                    email={Base64.decode(email)}
                    priceId={priceId}
                    serverUrl={serverUrl}
                    customerId={data}
                    planDuration={planDuration}
                    company={company}
                  />
                </Grid>
              )}
            </div>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default CheckoutPage;
