import React from 'react';
import { DataGrid } from '@mui/x-data-grid';

const Analytics = ({ data, CustomToolbar }) => {
    const columns = [
        {
            field: 'date',
            headerName: 'Date',
            sortable: true,
            width: 150,
            valueGetter: (value, row) => {
                return value.row.date.substring(0, 11)
            },
        },
        {
            field: 'uniqueLibraryUsers',
            headerName: 'Unique Library Users',
            sortable: true,
            width: 150
        },
        {
            field: 'avgLibrary',
            headerName: 'Avg Library screen time',
            sortable: true,
            width: 150
        },
        {
            field: 'maxLibrary',
            headerName: 'Library Max time',
            sortable: true,
            width: 150
        },
        {
            field: 'minLibrary',
            headerName: 'Library Min time',
            sortable: true,
            width: 150
        },
        {
            field: 'uniqueMyMomentUsers',
            headerName: 'Unique My Moment Users',
            sortable: true,
            width: 150
        },
        {
            field: 'avgMyMoment',
            headerName: 'Avg My Moment screen time',
            sortable: true,
            width: 150
        },
        {
            field: 'maxMyMoment',
            headerName: 'My Moment Max time',
            sortable: true,
            width: 150
        },
        {
            field: 'minMyMoment',
            headerName: 'My Moment Min time',
            sortable: true,
            width: 150
        },
        {
            field: 'uniqueMyInsightsUsers',
            headerName: 'Unique My Insights Users',
            sortable: true,
            width: 150
        },
        {
            field: 'avgMyInsights',
            headerName: 'Avg Insights screen time',
            sortable: true,
            width: 150
        },
        {
            field: 'maxMyInsights',
            headerName: 'My Insights Max time',
            sortable: true,
            width: 150
        },
        {
            field: 'minMyInsights',
            headerName: 'My Insights Min time',
            sortable: true,
            width: 150
        },
        {
            field: 'uniqueJourneyStageUsers',
            headerName: 'Unique Journey Stage Users',
            sortable: true,
            width: 150
        },
        {
            field: 'avgJourneyStage',
            headerName: 'Avg Journey screen time',
            sortable: true,
            width: 150
        },
        {
            field: 'maxJourneyStage',
            headerName: 'Journey Max time',
            sortable: true,
            width: 150
        },
        {
            field: 'minJourneyStage',
            headerName: 'Journey Min time',
            sortable: true,
            width: 150
        },
        {
            field: 'uniqueSelfGuidedUsers',
            headerName: 'Unique Self Guided Users',
            sortable: true,
            width: 150
        },
        {
            field: 'avgSelfGuided',
            headerName: 'Avg Self-Guided screen time',
            sortable: true,
            width: 150
        },
        {
            field: 'maxSelfGuided',
            headerName: 'Self Guided Max time',
            sortable: true,
            width: 150
        },
        {
            field: 'minSelfGuided',
            headerName: 'Self Guided Min time',
            sortable: true,
            width: 150
        },
        {
            field: 'uniqueSnapshotUsers',
            headerName: 'Unique Snapshot Users',
            sortable: true,
            width: 150
        },
        {
            field: 'avgSnapshot',
            headerName: 'Avg Snapshot screen time',
            sortable: true,
            width: 150
        },
        {
            field: 'maxSnapshot',
            headerName: 'Snapshot Max time',
            sortable: true,
            width: 150
        },
        {
            field: 'minSnapshot',
            headerName: 'Snapshot Min time',
            sortable: true,
            width: 150
        },
        {
            field: 'uniqueScalesUsers',
            headerName: 'Unique Scales Users',
            sortable: true,
            width: 150
        },
        {
            field: 'avgScales',
            headerName: 'Avg Scale screen time',
            sortable: true,
            width: 150
        },
        {
            field: 'maxScales',
            headerName: 'Scales Max time',
            sortable: true,
            width: 150
        },
        {
            field: 'minScales',
            headerName: 'Scales Min time',
            sortable: true,
            width: 150
        },
    ]

    return (
        <div>
            <div style={{ textAlign: 'center' }}> ANALYTICS REPORT (Units: seconds (mean across all users)) </div>
            <br></br>
            <div style={{ height: 500, width: '100%' }} className="d-grid gap-2 mt-3 justify-center">
                <DataGrid
                    rows={data}
                    columns={columns}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'date', sort: 'desc' }],
                        },
                    }}
                    slots={{
                        toolbar: CustomToolbar,
                    }} />
            </div>
        </div>
    );
};

export default Analytics;
