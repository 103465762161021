import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import App from './App';
import store from './redux/store';
import Spinner from './components/spinner/spinner';

import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from './utils/theme';
import { initAmplitude } from './utils/amplitude';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import * as serviceWorker from './serviceWorker';
import "./responsive.css";
// const store = configureStore();
import { history } from './utils/history';
import './utils/i18n';

initAmplitude();

const stripePromise = loadStripe('pk_live_knYVDaaiSN9fDekqaoBPYwDc');

ReactDOM.render(
  <React.Suspense fallback={<Spinner />}>
    <Provider store={store}>
      <Router history={history}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Elements stripe={stripePromise}>
            <App />
          </Elements>
        </ThemeProvider>
      </Router>
    </Provider>
  </React.Suspense>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
