import { BASE_CDN_URL } from './constants';

//returns coupon code
export const getFreeCoupon = (company, optionalCoupon) => {
  company = company.toLowerCase();
  switch (company) {
    case 'imd':
      return 'imd-6wks';
    case 'wildflowers':
      return 'wildflowers-3mth';
    case 'miindset':
      return 'miindset-fmth';
    case 'genuinetea':
      return 'genuinetea-3mth';
    case 'ibm':
      return 'ibm-hyr';
    case 'pcct':
      return 'pcct-lf';
    case 'kinduct':
      return 'kinduct-fmth';
    case 'mathguru':
      return 'mathguru-fmth';
    case 'cheoubc':
      return 'cheoubc';
    case 'cvmpc':
      return 'cvmpc-fyr';
    case 'mpc':
      return 'cvmpc-fyr';
    case 'gsc':
      return 'gsc-hyr';
    case 'synaptitude':
      return 'synaptitude-3mth';
    case 'camh':
      return 'camh2';
    case 'julienlacaille':
      return 'julienlacaille-fmth3';
    case 'lifedrop':
      return 'lifedrop-2mth';
    case 'carevoice':
      return 'carevoice';
    case 'eclectic':
      return 'eclectic-2mth';
    case 'glocoach':
      return 'glocoach-2mth';
    case 'rhythmedic':
      return 'rhythmedic-1mth';
    case 'windsor':
      return 'windsor-fyr';
    case 'seamless':
      return 'seamless-2yr';
    case 'mindfulnessworks':
      return 'seamless-2yr';
    case 'mbcs_ca':
      return 'mbcs_ca-2yr';
    case 'cheot1d':
      return 'cheot1d-2yr';
    case 'mskcc':
      return 'mskcc-2yr';
    case 'ptbiwest':
      return 'ubctbi';
    case 'sosv':
      return 'sosv-1mth2';
    case 'uw':
      return 'uw-fyr';
    case 'groundzero':
      return 'ground-zero-3mth';
    case 'obi':
      return 'obi-1mth';
    case 'mstbi':
      return 'mstbi-fyr';  
    default:
      return company;
  }
};

// returns if a company is elgible for free coupon
export const isFreeCoupon = (company) => {
  company = company.toLowerCase();
  switch (company) {
    case 'imd':
      return true;
    case 'wildflowers':
      return true;
    case 'miindset':
      return true;
    case 'genuinetea':
      return true;
    case 'ibm':
      return true;
    case 'julienlacaille':
      return true;
    case 'pcct':
      return true;
    case 'kinduct':
      return true;
    case 'mathguru':
      return true;
    case 'cheoubc':
      return true;
    case 'cvmpc':
      return true;
    case 'mpc':
      return true;
    case 'gsc':
      return true;
    case 'synaptitude':
      return true;
    case 'camh':
      return true;
    case 'windsor':
      return true;
    case 'lifedrop':
      return true;
    case 'carevoice':
      return true;
    case 'eclectic':
      return true;
    case 'glocoach':
      return true;
    case 'rhythmedic':
      return true;
    case 'seamless':
      return true;
    case 'mbcs_ca':
      return true;
    case 'cheot1d':
      return true;
    case 'mskcc':
      return true;
    case 'ptbiwest':
      return true;
    case 'sosv':
      return true;
    case 'uw':
      return true;
    case 'groundzero':
      return true;
    case 'obi':
      return true;
    case 'mstbi':
      return true;
    default:
      return false;
  }
};

// returns if a company is elgible for claim coupon
export const isClaimCoupon = (company) => {
  company = company.toLowerCase();
  switch (company) {
    default:
      return false;
  }
};

// returns company theme
export const getCompanyTheme = (company) => {
  company = company.toLowerCase();
  switch (company) {
    case 'imd':
      return '#253871';
    case 'miindset':
      return '#6200EE';
    case 'genuinetea':
      return '#FF7677';
    case 'ibm':
      return '#3B6CAA';
    case 'kinduct':
      return '#F5821F';
    case 'appscript':
      return '#429BC4';
    case 'mathguru':
      return '#FF66C4';
    case 'pchealth':
      return '#E1251B';
    case 'cheoubc':
    case 'ptbiwest':
      return '#8F4894';
    case 'cvmpc':
      return '#88CEF2';
    case 'mpc':
      return '#88CEF2';
    case 'gsc':
      return '#8DC63F';
    case 'league':
      return '#8F4894';
    case 'manonbreton':
      return '#80A3C7';
    case 'julienlacaille':
      return '#80A3C7';
    case 'mindfuljunkie':
      return '#235E95';
    case 'rst':
      return '#235E95';
    case 'synaptitude':
      return '#A65DF0';
    case 'gspace':
      return '#C3AD6C';
    case 'lumino':
      return '#193767';
    case 'synaptichacks':
      return '#F9B257';
    case 'sunlife':
      return '#EAAB00';
    case 'wework':
      return '#EAAB00';
    case 'mindfulnessworks':
      return '#441F5E';
    case 'camh':
    case 'cheot1d':
      return '#441F5E';
    case 'newcampus':
      return '#441F5E';
    case 'lifedrop':
      return '#000';
    case 'sosv':
      return '#88cef2';
    case 'eclectic':
      return '#f8ca70';
    case 'glocoach':
      return '#1b34c2';
    case 'seamless':
      return '#441F5E';
    case 'mbcs_ca':
      return '#441F5E';
    case 'snic':
      return '#409fbd';
    case 'mindfulnessworks':
      return '#88CEF2';
    case 'mskcc':
      return '#0073E0';
    case 'mstbi':
      return '#99102f';
    default:
      return '#F9B257';
  }
};

export const getCompanySloganTheme = (company) => {
  company = company.toLowerCase();
  switch (company) {
    case 'mskcc':
      return '#002569';
  }
  return null;
};

// returns company name
export const getCompanyName = (company) => {
  company = company.toLowerCase();
  switch (company) {
    case 'imd':
      return 'IMD';
    case 'wildflowers':
      return 'Wildflowers';
    case 'miindset':
      return 'Miindset';
    case 'genuinetea':
      return 'Genuine Tea';
    case 'ibm':
      return 'IBM';
    case 'pcct':
      return 'PC CT';
    case 'kinduct':
      return 'Kinduct';
    case 'appscript':
      return 'Appscript';
    case 'pchealth':
      return 'PC Health';
    case 'mathguru':
      return 'The Math Guru';
    case 'cheoubc':
      return 'CHEO';
    case 'cvmpc':
      return 'Carevoice and MPC';
    case 'mpc':
      return 'MPC';
    case 'gsc':
      return 'Green Shield Canada';
    case 'amdtx':
      return 'AmDTx';
    case 'mindfuljunkie':
      return 'Mindful Junkie';
    case 'synaptitude':
      return 'Synaptitude';
    case 'gspace':
      return 'G-Space';
    case 'synaptichacks':
      return 'Synaptic Hacks';
    case 'sunlife':
      return 'Sun Life';
    case 'camh':
      return 'CAMH';
    case 'lifedrop':
      return 'LifeDrop';
    case 'carevoice':
      return 'Carevoice';
    case 'rhythmedic':
      return 'Neuroglymse';
    case 'windsor':
      return 'Windsor';
    case 'seamless':
      return 'Seamless';
    case 'mbcs_ca':
      return 'Seamless';
    case 'snic':
      return 'SNIC';
    case 'mindfulnessworks':
      return 'Mindfulness Works for Organisations';
    case 'cheot1d':
      return 'CHEO';
    case 'mskcc':
      return 'CALM IT';
    case 'ptbiwest':
      return 'UBC';
    case 'uw':
      return 'VIPLAB';
    case 'groundzero':
      return 'Ground Zero';
    case 'obi':
      return 'OBI Patient/Community Workshop: Building Mutually Beneficial Collaborations';
    case 'mstbi':
      return 'University of Ottawa';
    default:
      return '';
  }
};

// returns company logo
export const getLogo = (company) => {
  company = company.toLowerCase();
  switch (company) {
    case 'imd':
      return BASE_CDN_URL + '/imd-logo.png';
    case 'wildflowers':
      return BASE_CDN_URL + '/am-logo.png';
    case 'miindset':
      return BASE_CDN_URL + '/miindset-logo.png';
    case 'genuinetea':
      return BASE_CDN_URL + '/genuine-tea-logo.png';
    case 'ibm':
      return BASE_CDN_URL + '/ibm-logo.png';
    case 'kinduct':
      return BASE_CDN_URL + '/kinduct-logo.png';
    case 'appscript':
      return BASE_CDN_URL + '/appscript-logo.png';
    case 'mathguru':
      return BASE_CDN_URL + '/math-guru-logo.png';
    case 'pchealth':
      return BASE_CDN_URL + '/pchealth-logo.png';
    case 'cheoubc':
      return BASE_CDN_URL + '/cheoubc-logo.png';
    case 'ptbiwest':
      return BASE_CDN_URL + '/UBC_logo.png';  
    case 'cvmpc':
      return BASE_CDN_URL + '/mwfo-logo.png';
    case 'mpc':
      return BASE_CDN_URL + '/mwfo-logo.png';
    case 'mindfulnessworks':
      return BASE_CDN_URL + '/mwfo-logo.png';
    case 'gsc':
      return BASE_CDN_URL + '/gsc-logo.png';
    case 'manonbreton':
      return BASE_CDN_URL + '/manonbreton-logo.png';
    case 'julienlacaille':
      return BASE_CDN_URL + '/mindspace_lacaille.png';
    case 'amdtx':
      return BASE_CDN_URL + '/am-icon.png';
    case 'mindfuljunkie':
      return BASE_CDN_URL + '/mindfuljunkie-logo.png';
    case 'synaptitude':
      return BASE_CDN_URL + '/synaptitude-logo.png';
    case 'gspace':
      return BASE_CDN_URL + '/gspace-logo.png';
    case 'lumino':
      return BASE_CDN_URL + '/lumino-logo.png';
    case 'synaptichacks':
      return BASE_CDN_URL + '/synaptichacks-logo.png';
    case 'sunlife':
      return BASE_CDN_URL + '/sunlife-logo.png';
    case 'camh':
      return BASE_CDN_URL + '/camh-logo.png';
    case 'lifedrop':
      return BASE_CDN_URL + '/lifedrop-logo.png';
    case 'uoft':
      return BASE_CDN_URL + '/uoft-logo.png';
    case 'achtsamengagiert':
      return BASE_CDN_URL + '/achtsamengagiert-logo.png';
    case 'mccn':
      return BASE_CDN_URL + '/mccn-logo.png';
    case 'cvcarnival':
      return BASE_CDN_URL + '/cvcarnival-logo.png';
    case 'newcampus':
      return BASE_CDN_URL + '/newcampus-logo.png';
    case 'gan':
      return BASE_CDN_URL + '/gan-logo.png';
    case 'wework':
      return BASE_CDN_URL + '/wework-logo.png';
    case 'friends':
      return BASE_CDN_URL + '/friends-logo.png';
    case 'mindfulnessdubai':
      return BASE_CDN_URL + '/mindfulnessdubai-logo.png';
    case 'urbanwellness':
      return BASE_CDN_URL + '/urbanwellness-logo.png';
    case 'openinnovation':
      return BASE_CDN_URL + '/openinnovation-logo.png';
    case 'myyoga':
      return BASE_CDN_URL + '/myyoga-logo.png';
    case 'healthaide':
      return BASE_CDN_URL + '/healthaide-bg.jpeg';
    case 'league':
      return BASE_CDN_URL + '/league-logo.png';
    case 'mindfulnessworks':
      return BASE_CDN_URL + '/mindfulnessworks-logo.png';
    case 'mindfulness':
      return BASE_CDN_URL + '/mindfulness-logo.png';
    case 'sdm':
      return BASE_CDN_URL + '/sdm-logo.png';
    case 'rst':
      return BASE_CDN_URL + '/rst-logo.png';
    case 'StartupCare':
      return BASE_CDN_URL + '/StatUpCare-Logo.png';
    case 'sosv':
      return BASE_CDN_URL + '/sosv-logo.png';
    case 'startupcare':
      return BASE_CDN_URL + '/startupcare-logo.png';
    case 'carevoice':
      return BASE_CDN_URL + '/carevoice-logo.png';
    case 'buisconsult':
      return BASE_CDN_URL + '/buisconsult-logo.png';
    case 'eclectic':
      return BASE_CDN_URL + '/b82DfLLk.png';
    case 'glocoach':
      return BASE_CDN_URL + '/GloCoach.png';
    case 'rhythmedic':
      return BASE_CDN_URL + '/rhythmedic-new.png';
    case 'windsor':
      return BASE_CDN_URL + '/windsor.png';
    case 'seamless':
    case 'mbcs_ca':
      return BASE_CDN_URL + '/seamless.png';
    case 'snic':
      return BASE_CDN_URL + '/snic-logo.png';
    case 'cheot1d':
      return BASE_CDN_URL + '/cheot1d-logo.png';
    case 'mskcc':
      return BASE_CDN_URL + '/mskcc-logo.png';
    case 'groundzero':
      return BASE_CDN_URL + '/groundzero-logo.jpg';
    case 'obi':
      return BASE_CDN_URL + '/obi-logo.png';
    case 'mstbi':
      return BASE_CDN_URL + '/mstbi-logo.png';
    default:
      return '';
  }
};

// returns company background image (right container)
export const getBackgroundImg = (company) => {
  company = company.toLowerCase();
  switch (company) {
    case 'imd':
      return BASE_CDN_URL + '/imd-bg.jpg';
    case 'wildflowers':
      return BASE_CDN_URL + '/wildflowers-bg.jpg';
    case 'miindset':
      return BASE_CDN_URL + '/miindset-bg.jpg';
    case 'genuinetea':
      return BASE_CDN_URL + '/genuine-tea-bg.jpg';
    case 'ibm':
      return BASE_CDN_URL + '/ibm-bg.jpg';
    case 'pcct':
      return BASE_CDN_URL + '/pcct-bg.jpg';
    case 'kinduct':
      return BASE_CDN_URL + '/kinduct-bg.jpg';
    case 'appscript':
      return BASE_CDN_URL + '/appscript-bg.jpg';
    case 'mathguru':
      return BASE_CDN_URL + '/math-guru-bg.jpg';
    case 'pchealth':
      return BASE_CDN_URL + '/pchealth-bg.jpg';
    case 'cheoubc':
      return BASE_CDN_URL + '/cheoubc-bg.jpg';
    case 'ptbiwest':
      return BASE_CDN_URL + '/cheoubc-bg.jpg';  
    case 'cvmpc':
      return BASE_CDN_URL + '/cvmpc-bg.jpg';
    case 'mpc':
      return BASE_CDN_URL + '/cvmpc-bg.jpg';
    case 'mindfulnessworks':
      return BASE_CDN_URL + '/cvmpc-bg.jpg';
    case 'gsc':
      return BASE_CDN_URL + '/gsc-bg.jpg';
    case 'manonbreton':
      return BASE_CDN_URL + '/manonbreton-bg.jpg';
    case 'julienlacaille':
      return BASE_CDN_URL + '/gsc-bg.jpg';
    case 'amdtx':
      return BASE_CDN_URL + '/fish-bg.jpg';
    case 'mindfuljunkie':
      return BASE_CDN_URL + '/mindfuljunkie-bg.jpg';
    case 'synaptitude':
      return BASE_CDN_URL + '/synaptitude-bg.jpg';
    case 'gspace':
      return BASE_CDN_URL + '/gspace-bg.jpg';
    case 'lumino':
      return BASE_CDN_URL + '/lumino-bg.jpg';
    case 'synaptichacks':
      return BASE_CDN_URL + '/synaptichacks-bg.jpg';
    case 'sunlife':
      return BASE_CDN_URL + '/sunlife-bg.jpg';
    case 'camh':
      return BASE_CDN_URL + '/camh-bg.jpg';
    case 'lifedrop':
      return BASE_CDN_URL + '/lifedrop-bg.png';
    case 'uoft':
      return BASE_CDN_URL + '/uoft-bg.jpeg';
    case 'achtsamengagiert':
      return BASE_CDN_URL + '/achtsamengagiert-bg.jpg';
    case 'mccn':
      return BASE_CDN_URL + '/mccn-bg.jpg';
    case 'newcampus':
      return BASE_CDN_URL + '/newcampus-bg.jpeg';
    case 'gan':
      return BASE_CDN_URL + '/gan-bg.png';
    case 'wework':
      return BASE_CDN_URL + '/wework-bg.png';
    case 'friends':
      return BASE_CDN_URL + '/friends-bg.png';
    case 'mindfulnessdubai':
      return BASE_CDN_URL + '/mindfulnessdubai-bg.png';
    case 'openinnovation':
      return BASE_CDN_URL + '/openinnovation-bg.png';
    case 'urbanwellness':
      return BASE_CDN_URL + '/urbanwellness-bg.png';
    case 'myyoga':
      return BASE_CDN_URL + '/myyoga-bg.jpeg';
    case 'league':
      return BASE_CDN_URL + '/league-bg.jpeg';
    case 'healthaide':
      return BASE_CDN_URL + '/healthaide-bg.jpeg';
    case 'mindfulnessworks':
      return BASE_CDN_URL + '/mindfulnessworks-bg.png';
    case 'mindfulness':
      return BASE_CDN_URL + '/mindfulness-bg.jpeg';
    case 'sdm':
      return BASE_CDN_URL + '/sdm-bg.jpeg';
    case 'rst':
      return BASE_CDN_URL + '/rst-bg.jpeg';
    case 'sosv':
      return BASE_CDN_URL + '/sosv-bg.jpeg';
    case 'StartupCare':
      return BASE_CDN_URL + '/StartupCare-bg.jpg';
    case 'carevoice':
      return BASE_CDN_URL + '/carevoice-bg.jpg';
    case 'buisconsult':
      return BASE_CDN_URL + '/buisconsult-bg.jpg';
    case 'cvcarnival':
      return BASE_CDN_URL + '/cvcarnival-bg.png';
    case 'snic':
      return BASE_CDN_URL + '/snic-bg.png';
    case 'cheot1d':
      return BASE_CDN_URL + '/cheoubc-bg.jpg';
    case 'mskcc':
      return BASE_CDN_URL + '/manonbreton-bg.jpg'; 
    case 'groundzero':
      return BASE_CDN_URL + '/groundzero-bg.jpg';
    case 'obi':
      return BASE_CDN_URL + '/obi-bg.jpg'; 
    case 'mstbi':
      return BASE_CDN_URL + '/camh-bg.jpg'; 
    default:
      return BASE_CDN_URL + '/fish-bg.jpg';
  }
};

export const returnErrorMessage = (serverMessage) => {
  switch (serverMessage) {
    case 'ServerAccountAlreadyRegistered':
      return 'ServerAccountAlreadyRegistered';
    case 'ServerInvalidPassword':
      return 'ServerInvalidPassword';
    case 'ServerInvalidEmail':
      return 'ServerInvalidEmail';
    case 'ServerNoMatchingAccount':
      return 'ServerNoMatchingAccount';
    case 'ServerPasswordIncorrect':
      return 'ServerPasswordIncorrect';
    case 'GSCNotVerified':
      return 'GSCNotVerified';
    case 'DependentInvalid':
      return 'DependentInvalid';
    default:
      return serverMessage;
  }
};

export const getDuration = (company) => {
  company = company.toLowerCase();
  switch (getFreeCoupon(company)) {
    case 'imd-6wks':
      return '4 months';
    case 'wildflowers-3mth':
    case 'genuinetea-3mth':
    case 'synaptitude-3mth':
      return '3 months';
    case 'miindset-fmth':
    case 'kinduct-fmth':
    case 'mathguru-fmth':
      return '1 month';
    case 'ibm-hyr':
    case 'gsc-hyr':
      return '6 months';
    case 'cheoubc':
    case 'ubctbi': 
    case 'cvmpc-fyr':
      return '1 year';
    case 'camh2':
      return '1 year';
    case 'rhythmedic-1mth':
      return '1 month';
    case 'windsor':
      return '1 year';
    case 'seamless-2yr':
      return '2 years';
    case 'julienlacaille-fmth3':
      return '1';
    case 'lifedrop-2mth':
      return '2';
    case 'carevoice':
      return '1 year';
    case 'eclectic':
      return '2 months';
    case 'glocoach':
      return '2 months';
    case 'snic':
      return '2 weeks';
    case 'mbcs_ca-2yr':
      return '2 years';
    case 'cheot1d-2yr':
      return '2 years';
    case 'mskcc-2yr':
      return '2 years';
    case 'sosv-1mth2':
      return '1 month';
    case 'uw-fyr':
      return '1 year';
    case 'ground-zero-3mth':
      return '3 months';
    case 'obi-1mth':
      return '1 month';
    case 'mstbi-fyr':
      return '1 year'; 
    default:
      return '2 weeks';
  }
};

export const pad = (value) => {
  return value < 10 ? '0' + value : value;
};

export const getTimezone = () => {
  var timezone = new Date().getTimezoneOffset();
  var sign = (timezone > 0) ? "-" : "+";
  var offset = Math.abs(timezone);
  var hours = pad(Math.floor(offset / 60));
  var minutes = pad(offset % 60);
  return sign + hours + ":" + minutes;
}
