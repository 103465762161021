import React, { useEffect } from 'react';
import { Grid, makeStyles, useMediaQuery } from '@material-ui/core';
// import { makeStyles } from '@material-ui/core/styles';
import { useRouteMatch, useHistory } from 'react-router-dom';
import CompanyLogo from '../../../components/company-logo/company-logo';
import Header from '../../../components/header/header';
import Footer from '../../../components/footer/footer';
import CompanyDetailsFlex from '../../../components/company-details-flex/company-details-flex';
import CustomButton from '../../../components/custom-button/custom-button';
import {
  getCompanyTheme,
  getBackgroundImg,
  getLogo,
  getDuration,
} from '../../../utils/company';
import { BASE_CDN_URL } from '../../../utils/constants';
import {
  sendAmplitudeData,
  detectPlatform,
} from '../../../utils/amplitude';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: 'calc(100vh - 70px - 47px)',
    overflow: 'auto',
  },
  mainContainer: {
    height: '100%',
  },
  leftBackgroundImg: {
    backgroundImage: `url(${BASE_CDN_URL + '/grey-bg.png'})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
  },
  rightBackgroundImg: {
    backgroundImage: `url(${getBackgroundImg('cheoubc')})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  leftContainer: {
    marginLeft: '41px',
    marginRight: '56px',
    marginTop: '54px',
    marginBottom: '20px',
  },
  textContainer: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#484848',
    marginTop: '43px',
  },
  storeContainer: {
    height: 'calc(100vh - 119px)',
  },
  buttonContainer: {
    marginTop: '81px',
  },
  imgContainer: {
    height: '28px',
    width: '248px',
  },
  link1: {
    width: '135px',
    height: '41px',
  },
  link2: {
    width: '156px',
    height: '60px',
  },
  link2Mob: {
    width: '156px',
    height: '60px',
    position: 'relative',
    left: '-10px',
  },
  linkWrapper: {
    display: 'flex',
    alignSelf: 'flex-end',
    alignItems: 'center',
    marginLeft: '41px',
    marginBottom: '31px',
    marginTop: '150px',
  },
  linkWrapperMob: {
    display: 'flex',
    alignSelf: 'flex-start',
    // alignItems: 'center',
    marginLeft: '41px',
    flexDirection: 'column',
    marginTop: '50px',
    marginBottom: '30px',
  },
}));

function LandingPage() {
  const sm = useMediaQuery('(max-width: 960px)');
  const leftPaneSize = sm ? 12 : 5;
  const classes = useStyles({ sm });
  const match = useRouteMatch();
  const history = useHistory();
  const { company } = match.params;
  const params = new URLSearchParams(history.location.search);
  const sid = params.get('sid');
  const group = params.get('group');
  const phase = params.get('phase');
  const cheoubc_phase = params.get('cheoubc_phase');
  const store = params.get('store');

  useEffect(() => {
    sendAmplitudeData('landing_page_viewed', {
      company: company,
      device_platform: detectPlatform(),
      time: new Date().toISOString(),
      origin: window.location.origin,
    });

    localStorage.setItem('sid', sid);
    localStorage.setItem('raw_sid', +sid);
    localStorage.setItem('group', group);
    localStorage.setItem('cheoubc_phase', cheoubc_phase);
    localStorage.setItem('phase', phase);
    localStorage.setItem('store', store);
  });

  const handleIosButton = () => {
    sendAmplitudeData('IOS_button_clicked', {
      company: company,
      device_platform: detectPlatform(),
      time: new Date().toISOString(),
      origin: window.location.origin,
    });
  };

  const handleAndroidButton = () => {
    sendAmplitudeData('Android_button_clicked', {
      company: company,
      device_platform: detectPlatform(),
      time: new Date().toISOString(),
      origin: window.location.origin,
    });
  };

  return (
    <React.Fragment>
      <Header />
      <div className={classes.root}>
        <Grid container className={classes.mainContainer}>
          <Grid
            item
            xs={leftPaneSize}
            className={classes.leftBackgroundImg}
          >
            <div className={classes.leftContainer}>
              <CompanyLogo
                company={company}
                style={classes.imgContainer}
              />
              <CompanyDetailsFlex
                slogan="WELCOME"
                companyName={company}
              >
                <p>Here is where you access AmDTx.</p>
              </CompanyDetailsFlex>
              {localStorage.getItem('sid') !== 'cheoubc_null' &&
              localStorage.getItem('sid') !== 'cheoubc_' &&
              (group === 'ubc0' ||
                group === 'cheo0' ||
                group === 'cheo1' ||
                group === 'ubc1' ||
                group !== null) ? (
                <div className={classes.buttonContainer}>
                  <CustomButton
                    onClick={() => history.push(`/${company}/signup`)}
                    backgroundColor={getCompanyTheme(company)}
                  >
                    ACCESS AmDTx
                  </CustomButton>
                </div>
              ) : null}
            </div>
          </Grid>
          {sm ? null : (
            <Grid
              item
              xs={7}
              className={classes.rightBackgroundImg}
            ></Grid>
          )}
        </Grid>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default LandingPage;
