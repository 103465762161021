import React, { useEffect } from 'react';
import { Grid, makeStyles, useMediaQuery } from '@material-ui/core';
// import { makeStyles } from '@material-ui/core/styles';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CompanyLogo from '../../../components/company-logo/company-logo';
import Header from '../../../components/header/header';
import Footer from '../../../components/footer/footer';
import CustomButton from '../../../components/custom-button/custom-button';
import {
  getCompanyTheme,
  getBackgroundImg,
  getLogo,
  getDuration,
} from '../../../utils/company';
import { BASE_CDN_URL } from '../../../utils/constants';
import SignUp from '../../../components/signup/signup';
import {
  sendAmplitudeData,
  detectPlatform,
} from '../../../utils/amplitude';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: 'calc(100vh - 70px - 47px)',
    overflow: 'auto',
    '& hr': {
      border: `4px solid #F9B257`,
      width: '75px',
      margin: '0',
    },
  },
  mainContainer: {
    height: '100%',
  },
  leftBackgroundImg: {
    backgroundImage: `url(${BASE_CDN_URL + '/grey-bg.png'})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
  },
  rightBackgroundImg: {
    backgroundImage: `url(${getBackgroundImg('mstbi')})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },
  leftContainer: {
    marginLeft: '41px',
    marginRight: '56px',
    marginTop: '54px',
    marginBottom: '20px',
  },
  textContainer: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#484848',
    marginTop: '43px',
  },
  storeContainer: {
    height: 'calc(100vh - 119px)',
  },
  buttonContainer: {
    marginTop: '81px',
  },
  imgContainer: {
    height: '70px',
    maxWidth: '300px',
    marginBottom: '30px',
  },
  instruction: {
    "@media (min-width: 280px)": {
      width: '230px',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '25px',
      lineHeight: '35px',
      display: 'flex',
      alignItems: 'center',
      color: '#484848',
      marginBottom: '40px',
    },
    "@media (min-width: 300px)": {
      width: '250px',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '25px',
      lineHeight: '35px',
      display: 'flex',
      alignItems: 'center',
      color: '#484848',
      marginBottom: '40px',
    },
    "@media (min-width: 768px)": {
      width: '320px',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '25px',
      lineHeight: '35px',
      display: 'flex',
      alignItems: 'center',
      color: '#484848',
      marginBottom: '40px',
    }
  },
  accountText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#484848',
    marginBottom: '12px',
  },
  imgContainer: {
    "@media (min-width: 280px)": {
      height: '40px !important',
    },
    "@media (min-width: 300px)": {
      height: '50px !important',
    },
    "@media (min-width: 768px)": {
      height: '100px !important',
    }
  },
  marginBottomLayout: {
    "@media (max-width: 768px)": {
      marginBottom: '150px'
    },
  }
}));

function SignupPage() {
  const sm = useMediaQuery('(max-width: 960px)');
  const leftPaneSize = sm ? 12 : 5;
  const classes = useStyles({ sm });
  const match = useRouteMatch();
  const history = useHistory();
  const { company } = match.params;
  const color = getCompanyTheme(company);
  const coloredLine = color && { border: `4px solid ${color}` };
  const { t } = useTranslation();

  useEffect(() => {
    sendAmplitudeData('signup_page_viewed', {
      company: company,
      device_platform: detectPlatform(),
      time: new Date().toISOString(),
      origin: window.location.origin,
    });
  });

  return (
    <React.Fragment>
      <Header />
      <div className={classes.root}>
        <Grid container className={classes.mainContainer}>
          <Grid
            item
            xs={leftPaneSize}
            className={classes.leftBackgroundImg}
          >
            <div className={classes.leftContainer}>
              <CompanyLogo
                company={company}
                style={classes.imgContainer}
              />
              <hr style={coloredLine}></hr>
              <div className={classes.instruction}>
                {t('mstbi_discount_text')}
              </div>
              <SignUp
                color={color}
                hideGoogle={false}
                sid={true}
              />
              <Grid container justifyContent="flex-start">
                <Grid
                  className={classes.marginBottomLayout}
                  item
                  onClick={() => history.push(`/${company}/signin`)}
                  style={{ cursor: 'pointer', marginBottom: '150px' }}
                >
                  {t('already_account_text')}
                </Grid>
              </Grid>
            </div>
          </Grid>
          {sm ? null : (
            <Grid
              item
              xs={7}
              className={classes.rightBackgroundImg}
            ></Grid>
          )}
        </Grid>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default SignupPage;
