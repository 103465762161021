import React, { useEffect } from 'react';
import { Grid, makeStyles, useMediaQuery } from '@material-ui/core';
// import { makeStyles } from '@material-ui/core/styles';
import { useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { useState } from 'react';

import Header from '../../../components/header/header';
import Footer from '../../../components/footer/footer';
import CompanyDetailsFlex from '../../../components/company-details-flex/company-details-flex';
import {
  getCompanyTheme,
  getBackgroundImg,
  getLogo,
  getDuration,
} from '../../../utils/company';
import { BASE_CDN_URL } from '../../../utils/constants';
import {
  sendAmplitudeData,
  detectPlatform,
} from '../../../utils/amplitude';
import ActivateButton from '../../../components/activate-button/activate-button';
import CompanyLogo from '../../../components/company-logo/company-logo';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: 'calc(100vh - 70px - 47px)',
    overflow: 'auto',
  },
  mainContainer: {
    height: '100%',
  },
  leftBackgroundImg: {
    backgroundImage: `url(${BASE_CDN_URL + '/grey-bg.png'})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '10% 10%',
  },
  rightBackgroundImg: {
    backgroundImage: `url(${getBackgroundImg('groundzero')})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },
  leftContainer: {
    marginLeft: '41px',
    marginRight: '56px',
    marginTop: '54px',
    marginBottom: '20px',
  },
  textContainer: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#484848',
    marginTop: '43px',
  },
  storeContainer: {
    height: 'calc(100vh - 119px)',
  },
  buttonContainer: {
    marginTop: '81px',
    paddingBottom: '80px',
  },
  mobButtonContainer: {
    marginTop: '40px',
    paddingBottom: '80px',
  },
  imgContainer: {
    "@media (min-width: 280px)": {
      height: '40px !important',
    },
    "@media (min-width: 300px)": {
      height: '50px !important',
    },
    "@media (min-width: 768px)": {
      height: '100px !important',
    }
  },
}));

function LandingPage() {
  const sm = useMediaQuery('(max-width: 960px)');
  const leftPaneSize = sm ? 12 : 5;
  const classes = useStyles({ sm });
  const match = useRouteMatch();
  const history = useHistory();
  const { company } = match.params;
  const { t } = useTranslation();
  const platform = detectPlatform();

  //sid
  const params = new URLSearchParams(history.location.search);
  const group = params.get('group');
  const phase = params.get('phase');
  const sid = params.get('sid');

  useEffect(() => {
    sendAmplitudeData('landing_page_viewed', {
      company: company,
      device_platform: detectPlatform(),
      time: new Date().toISOString(),
      origin: window.location.origin,
    });
    localStorage.setItem('sid', sid);
    localStorage.setItem('raw_sid', +sid);
    localStorage.setItem('group', group);
    localStorage.setItem('phase', phase);
    localStorage.setItem('store', null);

  });

  return (
    <React.Fragment>
      <Header />
      <div className={classes.root}>
        <Grid container className={classes.mainContainer}>
          <Grid
            item
            xs={leftPaneSize}
            className={classes.leftBackgroundImg}
          >
            <div className={classes.leftContainer}>
              <CompanyLogo
                company={company}
                style={classes.imgContainer}
              />
              <CompanyDetailsFlex
                slogan={t('groundzero_slogan')}
                companyName={company}
              >
                <p>{t('groundzero_landing1_p1')}</p>
                <p>{t('groundzero_landing1_p2')}</p>
                <ul>
                  <li><p>{t('groundzero_landing1_p2_text1')}</p></li>
                  <li><p>{t('groundzero_landing1_p2_text2')}</p></li>
                  <li><p>{t('groundzero_landing1_p2_text3')}</p></li>
                </ul>
                <p>{t('groundzero_landing2')}</p>
                <p>
                  <b>{t('groundzero_landing3')}</b>
                </p>
              </CompanyDetailsFlex>
              <ActivateButton
                label={t('groundzero_redeem')}
                backgroundColor={getCompanyTheme(company)}
                sm={sm}
                onClick={() => history.push(`/${company}/signup`)}
              />
            </div>
          </Grid>
          {sm ? null : (
            <Grid
              item
              xs={7}
              className={classes.rightBackgroundImg}
            ></Grid>
          )}
        </Grid>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default LandingPage;
