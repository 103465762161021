import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { getCompanyTheme, getCompanyName } from '../../utils/company';

const useStyles = makeStyles((theme) => ({
  root: {
    '& p': {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '15px',
      lineHeight: '19px',
      color: '#484848',
    },
    '& h2': {
      borderLeft: '4px solid #f9b257',
      paddingLeft: '16px',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '22px',
      lineHeight: '29px',
      color: '#484848',
      marginBottom: '35px',
    },
  },
}));

const CompanyDetails = ({ slogan, companyName, customText }) => {
  const classes = useStyles();
  const color = getCompanyTheme(companyName);
  const leftBorder = color && { borderLeft: `4px solid ${color}` };
  return (
    <div className={classes.root}>
      <h2 style={leftBorder}>{slogan}</h2>
      <div>
        {/* {companyName ? (
          <>
            <p>
              {getCompanyName(companyName)} and Mobio Interactive
              present Am Mindfulness, a clinically effective way to
              begin your personalised mindfulness journey.
            </p>
            <p>Track your emotions and feel the change within you.</p>
          </>
        ) : null} */}
        {customText ? (
          <>
            <p>{customText}</p>
            <p>Track your emotions and feel the change within you.</p>
          </>
        ) : null}
        <p>Am is the most effective meditation app ever developed.</p>
        <p>There is no reason for you to use anything else.</p>
        <p>
          Am lets you measure your stress using nothing more than your
          phone. No wearable required.
        </p>
        <p>Indulge in a variety of step-by-step Journeys.</p>
        <p>
          Ease into the you, You love. See yourself for the first
          time.
        </p>
      </div>
    </div>
  );
};

export default CompanyDetails;
