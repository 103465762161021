import React, { useMemo, useState } from "react"
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { BASE_API_URL } from '../../utils/constants';

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}

const Home = () => {
    const [isFetching, setIsFetching] = useState(false);
    const [snapshots, setSnapshots] = useState([]);
    const [questionnaires, setQuestionnaires] = useState([]);

    const SNAPSHOT_URL = BASE_API_URL + "/rhythmedic-snapshot?isAll=true";
    const QUESTIONNAIRE_URL = BASE_API_URL + "/rhythmedic-questionnaire";

    const snapshotColumns = [
        {
            field: 'group',
            headerName: 'Group',
            sortable: true,
            width: 200
        },
        {
            field: 'sid',
            headerName: 'SID',
            sortable: true,
            width: 300
        },
        {
            field: 'snapshot_uuid_pre',
            headerName: 'Snapshot UUID',
            sortable: true,
            width: 300
        },
        {
            field: 'snapshot_start_pre',
            headerName: 'Start time',
            sortable: true,
            width: 150
        },
        {
            field: 'snapshot_finish_pre',
            headerName: 'End time',
            sortable: true,
            width: 150
        },
        {
            field: 'heartrate_pre',
            headerName: 'Heartrate',
            sortable: true,
            width: 100
        },
        {
            field: 'hrv_low_freq_sum_pre',
            headerName: 'HRV Low Frequence Sum',
            sortable: true,
            width: 100
        },
        {
            field: 'hrv_high_freq_sum_pre',
            headerName: 'HRV High Frequence Sum',
            sortable: true,
            width: 100
        },
        {
            field: 'hrv_score_pre',
            headerName: 'HRV Score',
            sortable: true,
            width: 100
        },
        {
            field: 'reported_stress_pre',
            headerName: 'Reported stress',
            sortable: true,
            width: 100
        },
        {
            field: 'computer_vision_stress_pre',
            headerName: 'Computer vision stress',
            sortable: true,
            width: 100
        },
        {
            field: 'isSync',
            headerName: 'Sync',
            sortable: true,
            width: 100
        }
    ];

    const questionnaireColumns = [
        {
            field: 'group',
            headerName: 'Group',
            sortable: true,
            width: 200
        },
        {
            field: 'sid',
            headerName: 'SID',
            sortable: true,
            width: 400
        },
        {
            field: 'key',
            headerName: 'Key',
            sortable: true,
            width: 200
        },
        {
            field: 'questionnaire',
            headerName: 'Data',
            sortable: true,
            width: 600
        },
        {
            field: 'score',
            headerName: 'Score',
            sortable: true,
            width: 200
        },
        {
            field: 'dateTime',
            headerName: 'Date time',
            sortable: true,
            width: 200
        }
    ]

    const getAllSnapshots = async () => {
        const token = localStorage.getItem("auth-neuroglympse");
        const resSnapshot = await axios.get(SNAPSHOT_URL, {
            headers: {
                "Authorization": "Bearer " + token
            }
        });
        if (resSnapshot.status === 200 && resSnapshot.data) {
            let data = resSnapshot.data;
            for (let i = 0; i < data.length; i++) {
                data[i].id = i;
            }
            setSnapshots(data);
        } else {
            toast.error("GET SNAPSHOT FAILED")
        }
    }

    const getAllQuestionnaires = async () => {
        const token = localStorage.getItem("auth-neuroglympse");
        const resQuestionnaire = await axios.get(QUESTIONNAIRE_URL, {
            headers: {
                "Authorization": "Bearer " + token
            }
        });
        if (resQuestionnaire.status === 200 && resQuestionnaire.data) {
            let data = resQuestionnaire.data;
            for (let i = 0; i < data.length; i++) {
                data[i].id = i;
            }
            setQuestionnaires(data);
        } else {
            toast.error("GET QUESTIONNAIRE FAILED")
        }
    }

    useMemo(() => {
        const token = localStorage.getItem("auth-neuroglympse");
        let time = Date.now();
        const lastTimeLogin = + localStorage.getItem("time");

        if (!token || time - lastTimeLogin > 60 * 60 * 1000) {
            localStorage.removeItem("auth-neuroglympse");
            window.location.href = "/neuroglympse-login";
        } else {
            getAllSnapshots();
            getAllQuestionnaires();
        }
    }, []);

    if (isFetching) {
        return (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <div className="home">
            <br></br>
            <div style={{ textAlign: 'center' }}> READINGS (Last 30 days) </div>
            <br></br>
            <div style={{ height: 500, width: '100%' }} className="d-grid gap-2 mt-3 justify-center">
                <DataGrid
                    rows={snapshots}
                    columns={snapshotColumns}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'snapshot_start_pre', sort: 'desc' }],
                        },
                    }}
                    slots={{
                        toolbar: CustomToolbar,
                    }} />
            </div>
            <br></br>
            <div style={{ textAlign: 'center' }}> ASSESSMENTS </div>
            <br></br>
            <div style={{ height: 500, width: '100%' }} className="d-grid gap-2 mt-3 justify-center">
                <DataGrid
                    rows={questionnaires}
                    columns={questionnaireColumns}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'dateTime', sort: 'desc' }],
                        },
                    }}
                    slots={{
                        toolbar: CustomToolbar,
                    }} />
            </div>
            <br></br>

            <ToastContainer />
        </div>
    )
}
export default Home;
