import React, { useEffect } from 'react';
import { Grid, makeStyles, useMediaQuery } from '@material-ui/core';
// import { makeStyles } from '@material-ui/core/styles';
import { useRouteMatch, useHistory, Link } from 'react-router-dom';
import CompanyLogo from '../../../components/company-logo/company-logo';
import Header from '../../../components/header/header';
import Footer from '../../../components/footer/footer';
import {
  getCompanyTheme,
  getBackgroundImg,
  getLogo,
} from '../../../utils/company';
import { BASE_CDN_URL } from '../../../utils/constants';
import SignIn from '../../../components/signin/signin';
import {
  sendAmplitudeData,
  detectPlatform,
} from '../../../utils/amplitude';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: 'calc(100vh - 70px - 47px)',
    overflow: 'auto',
    '& hr': {
      border: `4px solid #F9B257`,
      width: '75px',
      margin: '0',
    },
  },
  mainContainer: {
    height: '100%',
  },
  leftBackgroundImg: {
    backgroundImage: `url(${BASE_CDN_URL + '/grey-bg.png'})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
  },
  rightBackgroundImg: {
    backgroundImage: `url(${getBackgroundImg('camh')})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  leftContainer: {
    marginLeft: '41px',
    marginRight: '56px',
    marginTop: '54px',
    marginBottom: '20px',
  },
  textContainer: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#484848',
    marginTop: '43px',
  },
  storeContainer: {
    height: 'calc(100vh - 119px)',
  },
  buttonContainer: {
    marginTop: '81px',
  },
  imgContainer: {
    height: '36px',
    width: '95px',
    marginBottom: '35px',
  },
  instruction: {
    width: '236px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '25px',
    lineHeight: '35px',
    display: 'flex',
    alignItems: 'center',
    color: '#484848',
    marginBottom: '40px',
  },
  accountText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#484848',
    marginBottom: '12px',
  },
}));

function SigninPage() {
  const md = useMediaQuery('(max-width: 1050px)');
  const leftPaneSize = md ? 12 : 5;
  const classes = useStyles({ md });
  const match = useRouteMatch();
  const history = useHistory();
  const { company } = match.params;
  const color = getCompanyTheme(company);
  const coloredLine = color && { border: `4px solid ${color}` };

  useEffect(() => {
    sendAmplitudeData('signin_page_viewed', {
      company: company,
      device_platform: detectPlatform(),
      time: new Date().toISOString(),
      origin: window.location.origin,
    });
  });

  return (
    <React.Fragment>
      <Header en fr />
      <div className={classes.root}>
        <Grid container className={classes.mainContainer}>
          <Grid
            item
            xs={leftPaneSize}
            className={classes.leftBackgroundImg}
          >
            <div className={classes.leftContainer}>
              <CompanyLogo company={company} />
              <hr style={coloredLine}></hr>
              <div className={classes.instruction}>
                Fill the form and get Premium access to Am at no
                charge.
              </div>
              <SignIn color={color} sid={true} group={true} />
              <Grid container justifyContent="flex-start">
                <Grid
                  item
                  onClick={() => history.push(`/${company}/signup`)}
                  style={{ cursor: 'pointer', marginBottom: '150px' }}
                >
                  I do not have an account
                </Grid>
              </Grid>
            </div>
          </Grid>
          {md ? null : (
            <Grid
              item
              xs={7}
              className={classes.rightBackgroundImg}
            ></Grid>
          )}
        </Grid>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default SigninPage;
