import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { getCompanyTheme } from '../../utils/company';
// import { useRouteMatch } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function Spinner() {
  //   const classes = useStyles();
  //   const match = useRouteMatch();
  //   const { company } = match.params;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        // flex: '1',
        height: '90vh',
        alignItems: 'center',
      }}
    >
      <CircularProgress size={100} style={{ color: 'black' }} />
    </div>
  );
}
