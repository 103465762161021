import React, { useState } from "react"
import "./login.css"
import useForm from "./useForm.js";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    Grid,
    TextField,
} from '@material-ui/core';
import CustomButton from '../../components/custom-button/custom-button.jsx';
import { BASE_API_URL } from '../../utils/constants.js';

const Login = (props) => {
    const [loggedIn, setLoggedIn] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [loggedSuccessfully, setLoggedSuccessfully] = useState(true);

    const LOGIN_URL = BASE_API_URL + "/account/login";

    const validate = (_values) => {
        let _errors = {};
        if (!_values.username) {
            _errors.username = "Username is required";
        }

        if (!_values.password) {
            _errors.password = "Password is required";
        }
        return _errors;
    }

    const login = async () => {
        let body = {
            user: values.username,
            password: values.password,
            clientVersion: "1.0.3"
        }
        const headers = {
            'Content-Type': 'application/json'
        };
        try {
            const res = await axios.post(LOGIN_URL, body);
            if (res.status === 200 && res.data) {
                //save token to localstorage:
                let token = res.data.token;

                let info = token.split('.')[1];
                let auth = JSON.parse(atob(info)).auth;
                if ("admin" != auth && "admin_neuroglympse" != auth) {
                    setLoggedSuccessfully(false);
                    toast.error(<div>Login failed! <br />You do not have permission for access.</div>);
                    return;
                }
                toast.success("Login successfully!")
                localStorage.setItem('auth-neuroglympse', token);
                localStorage.setItem('time', Date.now());
                setLoggedIn(true);
                window.location.href = "/neuroglympse-data";
            } else {
                setLoggedSuccessfully(false);
                toast.error(<div>Login failed! <br />Please check your username and password.</div>)
            }
        } catch (e) {
            setLoggedSuccessfully(false);
            toast.error(<div>Login failed! <br />Some errors happened.</div>)
        }
    }

    const { values, errors, handleChange, handleSubmit } = useForm(login, validate);

    return (
        <div className="login-form-container">
            <form className="login-form" onSubmit={handleSubmit}>
                <div className="login-form-content">
                    <div>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            <img style={{ width: '250px' }} src={"/logo.png"} />
                        </div>
                    </div>

                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            id="username"
                            label={'Enter Username'}
                            name="username"
                            autoComplete="username"
                            value={values.username || ""}
                            onChange={handleChange}
                        />
                    </Grid>

                    <br></br>

                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            name="password"
                            label={'Enter password'}
                            type={passwordVisible ? 'text' : 'password'}
                            id="password"
                            autoComplete="current-password"
                            value={values.password}
                            onChange={handleChange}
                        />
                    </Grid>

                    <br></br>
                    <CustomButton
                        type="submit"
                        backgroundColor={'green'}
                        style={{ width: '90px', marginBottom: '14px' }}
                    >
                        {(
                            'Login'
                        )}
                    </CustomButton>
                </div>
            </form>
            <ToastContainer />
        </div>
    )
}

export default Login;
