import amplitude from 'amplitude-js';
import {
  //   isBrowser,
  isAndroid,
  isWinPhone,
  isIOS,
  isWindows,
  isMacOs,
} from 'react-device-detect';

export const initAmplitude = () => {
  amplitude.getInstance().init('35255db267709996349d491c1aca0de8');
};

export const setAmplitudeUserDevice = (installationToken) => {
  amplitude.getInstance().setDeviceId(installationToken);
};

export const setAmplitudeUserId = (userId) => {
  amplitude.getInstance().setUserId(userId);
};

export const setAmplitudeUserProperties = (properties) => {
  amplitude.getInstance().setUserProperties(properties);
};

export const sendAmplitudeData = (eventType, eventProperties) => {
  amplitude.getInstance().logEvent(eventType, eventProperties);
};

export const detectPlatform = () => {
  if (isMacOs) {
    return 'Mac';
  } else if (isWindows) {
    return 'Windows';
  } else if (isIOS) {
    return 'IOS';
  } else if (isAndroid) {
    return 'Android';
  } else if (isWinPhone) {
    return 'WinPhone';
  } else {
    return 'Others';
  }
};
