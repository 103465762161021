import React, { useEffect } from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import CompanyLogo from '../../../components/company-logo/company-logo';
import Header from '../../../components/header/header';
import Footer from '../../../components/footer/footer';
import MemberDetails from '../../../components/member-details/member-details';
import { getLogo } from '../../../utils/company';
import { BASE_CDN_URL } from '../../../utils/constants';
import {
  sendAmplitudeData,
  detectPlatform,
} from '../../../utils/amplitude';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: 'calc(100vh - 70px - 47px)',
    overflow: 'auto',
  },
  mainContainer: {
    height: '100%',
    backgroundImage: `url(${BASE_CDN_URL + '/grey-bg.png'})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
  },
  leftBackgroundImg: {
    backgroundImage: `url(${BASE_CDN_URL + '/grey-bg.png'})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
  },
  innerContainer: {
    marginTop: '70px',
    marginLeft: '146px',
    marginRight: '146px',
  },
  innerContainerMob: {
    marginTop: '35px',
    marginLeft: '25px',
    marginRight: '25px',
  },
  imgContainer: {
    height: '28px',
    width: '248px',
    marginBottom: '30px',
  },
  link1: {
    width: '135px',
    height: '41px',
  },
  link2: {
    width: '156px',
    height: '60px',
  },
  linkWrapper: {
    display: 'flex',
    alignSelf: 'flex-end',
    alignItems: 'center',
    marginLeft: '41px',
    marginBottom: '31px',
  },
  linkWrapperMob: {
    display: 'flex',
    alignSelf: 'flex-end',
    alignItems: 'center',
    marginLeft: '20px',
    marginBottom: '31px',
    flexDirection: 'column',
  },
}));

function CartPage(props) {
  const xs = useMediaQuery('(max-width: 631px)');
  const classes = useStyles({ xs });
  // const customMargin= xs ? {classes.innerContainerMob} : {classes.innerContainer}
  const history = useHistory();
  // console.log('cartpage', history);
  const { company } = history.location.state;

  useEffect(() => {
    sendAmplitudeData('cart_page_viewed', {
      company: company,
      device_platform: detectPlatform(),
      time: new Date().toISOString(),
      origin: window.location.origin,
    });
  });

  const handleIosButton = () => {
    sendAmplitudeData('IOS_button_clicked', {
      company: company,
      device_platform: detectPlatform(),
      time: new Date().toISOString(),
      origin: window.location.origin,
    });
  };

  const handleAndroidButton = () => {
    sendAmplitudeData('Android_button_clicked', {
      company: company,
      device_platform: detectPlatform(),
      time: new Date().toISOString(),
      origin: window.location.origin,
    });
  };
  return (
    <React.Fragment>
      <Header />
      <div className={classes.root}>
        <Grid container className={classes.mainContainer}>
          <Grid item xs={12}>
            <div
              className={
                xs
                  ? classes.innerContainerMob
                  : classes.innerContainer
              }
            >
              <CompanyLogo
                company={company}
                style={classes.imgContainer}
              />
              {/* <PricingCard title="MONTHLY" /> */}
              <MemberDetails history={history} />
            </div>
          </Grid>
          <div
            className={
              xs ? classes.linkWrapperMob : classes.linkWrapper
            }
          >
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://apps.apple.com/ca/app/i-am-mindful/id1300628961?utm_campaign=MI%20Website%20&utm_medium=link&utm_source=MI%20Website"
              onClick={handleIosButton}
            >
              <img
                src="https://meditationcontent.blob.core.windows.net/meditationcontent/estore/v2/AppStore.png"
                alt="app-store-link"
                className={classes.link1}
              />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://play.google.com/store/apps/details?id=com.mobiointeractive.wildflowers2&hl=en&utm_source=MI%20Website%20&utm_medium=link&utm_campaign=MI%20Website%20"
              onClick={handleAndroidButton}
            >
              <img
                src="https://meditationcontent.blob.core.windows.net/meditationcontent/estore/v2/GooglePlay.png"
                alt="app-store-link"
                className={classes.link2}
              />
            </a>
          </div>
        </Grid>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default CartPage;
