import React from 'react';
import { DataGrid } from '@mui/x-data-grid';

const EstoreMetrics = ({ data, CustomToolbar }) => {
    const columns = [
        {
            field: 'date',
            headerName: 'Date',
            sortable: true,
            width: 300
        },
        {
            field: 'landingPage',
            headerName: 'Landing Page',
            sortable: true,
            width: 300
        },
        {
            field: 'activate',
            headerName: 'Activate',
            sortable: true,
            width: 300
        },
        {
            field: 'signup',
            headerName: 'Sign Up',
            sortable: true,
            width: 300
        },
        {
            field: 'store',
            headerName: 'Store',
            sortable: true,
            width: 300
        }
    ]


    return (
        <div>
            <div style={{ textAlign: 'center' }}> METRICS ESTORE (Number of actions in each page per day) </div>            
            <br></br>
            <div style={{ height: 500, width: '100%' }} className="d-grid gap-2 mt-3 justify-center">
                <DataGrid
                    rows={data}
                    columns={columns}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'date', sort: 'desc' }],
                        },
                    }}
                    slots={{
                        toolbar: CustomToolbar,
                    }} />
            </div>
        </div>
    );
};

export default EstoreMetrics;
