import React, { useMemo, useState } from "react"
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { BASE_API_URL } from '../../utils/constants';
import Toolbar from '@material-ui/core/Toolbar';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import EstoreMetrics from "./estore-metrics";
import Analytics from "./analytics";
import NeuroglymseAnalytics from "./neuroglymse-analytics";
import TrackingLogin from "./tracking-login";
import TrackingLibraryLoadingTime from "./tracking-library-loading-time";
import TrackingSessionDownloadTime from "./tracking-session-download-time";

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}

const Home = () => {
    const [isFetching, setIsFetching] = useState(false);
    const [reportType, setReportType] = useState('METRICS');
    const [analytics, setAnalytics] = useState([]);
    const [trackingLogins, setTrackingLogins] = useState([]);
    const [trackingLibraryLoadingTime, setTrackingLibraryLoadingTime] = useState([]);
    const [trackingSessionDownloadTime, setTrackingSessionDownloadTime] = useState([]);
    const [metrics, setMetrics] = useState([]);
    const [neuroglympseTracking, setNeuroglympseTracking] = useState([]);

    const [isFirstLoadAnalytics, setIsFirstLoadAnalytics] = useState(false);
    const [isFirstLoadTrackingLogin, setIsFirstLoadTrackingLogin] = useState(false);
    const [isFirstLoadTrackingLibraryLoadingTime, setIsFirstLoadTrackingLibraryLoadingTime] = useState(false);
    const [isFirstLoadTrackingSessionDownloadTime, setIsFirstLoadTrackingSessionDownloadTime] = useState(false);
    const [isFirstLoadMetrics, setIsFirstLoadMetrics] = useState(false);
    const [isFirstLoadNeuroglympseTracking, setIsFirstLoadNeuroglympseTracking] = useState(false);

    const ANALYTICS_URL = BASE_API_URL + "/account/analytics";
    const TRACKING_LOGIN_URL = BASE_API_URL + "/account/total-tracking";
    const TRACKING_URL = BASE_API_URL + "/account/total-tracking-type";
    const METRICS_URL = BASE_API_URL + "/estore-metrics";
    const TOTAL_TRACKING_URL = BASE_API_URL + "/rhythmedic-total-tracking";

    const getAnalytics = async () => {
        const token = localStorage.getItem("auth");
        const res = await axios.get(ANALYTICS_URL, {
            headers: {
                "Authorization": "Bearer " + token
            }
        });
        if (res.status === 200 && res.data) {
            let data = res.data;
            for (let i = 0; i < data.length; i++) {
                data[i].id = i;
            }
            setAnalytics(data);
        } else {
            toast.error("GET ANALYTICS FAILED")
        }
    }

    const getTrackingLogin = async () => {
        const token = localStorage.getItem("auth");
        const res = await axios.get(TRACKING_LOGIN_URL, {
            headers: {
                "Authorization": "Bearer " + token
            }
        });
        if (res.status === 200 && res.data) {
            let data = res.data;
            for (let i = 0; i < data.length; i++) {
                data[i].date = data[i].startTime.substring(0, 10);
                data[i].id = i;
            }
            setTrackingLogins(data);
        } else {
            toast.error("GET TRACKING LOGIN FAILED")
        }
    }

    const getTrackingLibraryLoadingTime = async () => {
        const token = localStorage.getItem("auth");
        const res = await axios.get(TRACKING_URL + "?type=LIBRARY_LOADING_TIME", {
            headers: {
                "Authorization": "Bearer " + token
            }
        });
        if (res.status === 200 && res.data) {
            let data = res.data;
            for (let i = 0; i < data.length; i++) {
                data[i].date = data[i].startTime.substring(0, 10);
                data[i].id = i;
            }
            setTrackingLibraryLoadingTime(data);
        } else {
            toast.error("GET TRACKING LIBRARY LOADING TIME FAILED")
        }
    }

    const getTrackingSessionDownloadTime = async () => {
        const token = localStorage.getItem("auth");
        const res = await axios.get(TRACKING_URL + "?type=SESSION_DOWNLOAD_TIME", {
            headers: {
                "Authorization": "Bearer " + token
            }
        });
        if (res.status === 200 && res.data) {
            let data = res.data;
            for (let i = 0; i < data.length; i++) {
                data[i].date = data[i].startTime.substring(0, 10);
                data[i].id = i;
            }
            setTrackingSessionDownloadTime(data);
        } else {
            toast.error("GET TRACKING SESSION DOWNLOAD TIME FAILED")
        }
    }

    const getMetrics = async () => {
        const token = localStorage.getItem("auth");
        const res = await axios.get(METRICS_URL, {
            headers: {
                "Authorization": "Bearer " + token
            }
        });
        if (res.status === 200 && res.data) {
            let data = res.data;
            for (let i = 0; i < data.length; i++) {
                data[i].id = i;
            }
            setMetrics(data);
        } else {
            toast.error("GET METRICS FAILED")
        }
    }

    const getNeuroglymseTracking = async () => {
        const token = localStorage.getItem("auth");
        const res = await axios.get(TOTAL_TRACKING_URL, {
            headers: {
                "Authorization": "Bearer " + token
            }
        });
        if (res.status === 200 && res.data) {
            let data = res.data;
            for (let i = 0; i < data.length; i++) {
                data[i].id = i;
            }
            setNeuroglympseTracking(data);
        } else {
            toast.error("GET TOTAL TRACKING FAILED")
        }
    }

    useMemo(() => {
        const token = localStorage.getItem("auth");
        let time = Date.now();
        const lastTimeLogin = + localStorage.getItem("time");

        if (!token || time - lastTimeLogin > 60 * 60 * 1000) {
            localStorage.removeItem("auth");
            window.location.href = "/apidata";
        } else {
            getMetrics();
        }
    }, []);

    if (isFetching) {
        return (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
            </Box>
        );
    }

    const handleChangeReportType = (event) => {
        let type = event.target.value;

        if (type == 'METRICS') {
            if (!isFirstLoadMetrics) {
                getMetrics();
            }
            setIsFirstLoadMetrics(true);
        }

        if (type == 'ANALYTICS_REPORT') {
            if (!isFirstLoadAnalytics) {
                getAnalytics();
            }
            setIsFirstLoadAnalytics(true);
        }

        if (type == 'NEUROGLYMPSE_REPORT') {
            if (!isFirstLoadNeuroglympseTracking) {
                getNeuroglymseTracking();
            }
            setIsFirstLoadNeuroglympseTracking(true);
        }

        if (type == 'TRACKING_LOGIN') {
            if (!isFirstLoadTrackingLogin) {
                getTrackingLogin();
            }
            setIsFirstLoadTrackingLogin(true);
        }

        if (type == 'TRACKING_LIBRARY_LOADING_TIME') {
            if (!isFirstLoadTrackingLibraryLoadingTime) {
                getTrackingLibraryLoadingTime();
            }
            setIsFirstLoadTrackingLibraryLoadingTime(true);
        }

        if (type == 'TRACKING_DOWNLOAD_SESSION_TIME') {
            if (!isFirstLoadTrackingSessionDownloadTime) {
                getTrackingSessionDownloadTime();
            }
            setIsFirstLoadTrackingSessionDownloadTime(true);
        }

        setReportType(type);
    };

    return (
        <div className="home">
            <Toolbar>
                <Select
                    value={reportType}
                    onChange={handleChangeReportType}
                >
                    <MenuItem value={'METRICS'}>METRICS</MenuItem>
                    <MenuItem value={'ANALYTICS_REPORT'}>ANALYTICS REPORT</MenuItem>
                    <MenuItem value={'TRACKING_LOGIN'}>TRACKING LOGIN</MenuItem>
                    <MenuItem value={'TRACKING_LIBRARY_LOADING_TIME'}>TRACKING LIBRARY LOADING TIME</MenuItem>
                    <MenuItem value={'TRACKING_DOWNLOAD_SESSION_TIME'}>TRACKING DOWNLOAD SESSION TIME</MenuItem>
                    <MenuItem value={'NEUROGLYMPSE_REPORT'}>NEUROGLYMPSE REPORT</MenuItem>
                </Select>
            </Toolbar>
            <br></br>
            {reportType == 'ANALYTICS_REPORT' ?
                <>
                    <Analytics data={analytics} CustomToolbar={CustomToolbar}></Analytics>
                </> : null}

            {reportType == 'TRACKING_LOGIN' ?
                <>
                    <TrackingLogin data={trackingLogins} CustomToolbar={CustomToolbar}></TrackingLogin>
                </> : null}

            {reportType == 'TRACKING_LIBRARY_LOADING_TIME' ?
                <>
                    <TrackingLibraryLoadingTime data={trackingLibraryLoadingTime} CustomToolbar={CustomToolbar}></TrackingLibraryLoadingTime>  
                </> : null}    

            {reportType == 'TRACKING_DOWNLOAD_SESSION_TIME' ?
                <>
                    <TrackingSessionDownloadTime data={trackingSessionDownloadTime} CustomToolbar={CustomToolbar}></TrackingSessionDownloadTime>  
                </> : null}    

            {reportType == 'METRICS' ?
                <>
                    <EstoreMetrics data={metrics} CustomToolbar={CustomToolbar}></EstoreMetrics>
                </> : null}


            {reportType == 'NEUROGLYMPSE_REPORT' ?
                <>
                    <NeuroglymseAnalytics data={neuroglympseTracking} CustomToolbar={CustomToolbar}></NeuroglymseAnalytics>
                </> : null}

            <ToastContainer />
        </div>
    )
}
export default Home;
