import React, { useEffect } from 'react';
import { Grid, makeStyles, useMediaQuery } from '@material-ui/core';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CompanyLogo from '../../../components/company-logo/company-logo';
import Header from '../../../components/header/header';
import Footer from '../../../components/footer/footer';
import CompanyDetailsFlex from '../../../components/company-details-flex/company-details-flex';
import ActivateButton from '../../../components/activate-button/activate-button';
import SocialIcons from '../../../components/social-icons/social-icons';
import {
  getCompanyTheme,
  getBackgroundImg,
  getLogo,
} from '../../../utils/company';
import { BASE_CDN_URL } from '../../../utils/constants';
import {
  sendAmplitudeData,
  detectPlatform,
} from '../../../utils/amplitude';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: 'calc(100vh - 70px - 47px)',
    overflow: 'auto',
  },
  mainContainer: {
    height: '100%',
  },
  leftBackgroundImg: {
    backgroundImage: `url(${BASE_CDN_URL + '/grey-bg.png'})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
  },
  rightBackgroundImg: {
    backgroundImage: `url(${getBackgroundImg('cvmpc')})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  leftContainer: {
    marginLeft: '41px',
    marginRight: '56px',
    marginTop: '54px',
    marginBottom: '20px',
  },
  textContainer: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#484848',
    marginTop: '43px',
  },
  storeContainer: {
    height: 'calc(100vh - 119px)',
  },
  imgContainer: {
    height: '57px',
    width: '117px',
  },
}));

function LandingPage() {
  const sm = useMediaQuery('(max-width: 960px)');
  const leftPaneSize = sm ? 12 : 5;
  const classes = useStyles({ sm });
  const match = useRouteMatch();
  const history = useHistory();
  const { company } = match.params;
  const { t } = useTranslation();
  const platform = detectPlatform();

  useEffect(() => {
    sendAmplitudeData('landing_page_viewed', {
      company: company,
      device_platform: detectPlatform(),
      time: new Date().toISOString(),
      origin: window.location.origin,
    });

    localStorage.setItem('sid', null);
    localStorage.setItem('group', null);
    localStorage.setItem('phase', null);
    localStorage.setItem('store', null);
  });

  return (
    <React.Fragment>
      <Header en cn />
      <div className={classes.root}>
        <Grid container className={classes.mainContainer}>
          <Grid
            item
            xs={leftPaneSize}
            className={classes.leftBackgroundImg}
          >
            <div className={classes.leftContainer}>
              <CompanyLogo company={company} />
              <CompanyDetailsFlex
                slogan={t('cvmpc_slogan')}
                companyName={company}
              >
                <p>
                  {t('cvmpc_landing1_p1')} <b>AmDTx</b>
                  {t('cvmpc_landing1_p2')}
                </p>
                <p>{t('cvmpc_landing2')}</p>
                <div>{t('am_love')}</div>
                <p>
                  <b>{t('cvmpc_landing3')} </b>
                </p>
              </CompanyDetailsFlex>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <ActivateButton
                  label={t('activate')}
                  backgroundColor={getCompanyTheme(company)}
                  sm={sm}
                  style={{
                    margin:
                      platform === 'Android' || platform === 'IOS'
                        ? '5px 0'
                        : '41px 0',
                  }}
                  onClick={() => history.push(`/${company}/signup`)}
                />
                <SocialIcons
                  sm={sm}
                  platform={platform}
                  company={company}
                  style={{
                    marginTop:
                      platform === 'Android' || platform === 'IOS'
                        ? '5px'
                        : '30px',
                  }}
                />
              </div>
            </div>
          </Grid>
          {sm ? null : (
            <Grid
              item
              xs={7}
              className={classes.rightBackgroundImg}
            ></Grid>
          )}
        </Grid>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default LandingPage;
