import {useEffect, useState} from "react";

const useForm = (callback, validate) => {
    const [isSubmit, setIsSubmit] = useState(false);
    const [values, setValues] = useState({});
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (Object.keys(errors).length === 0 && isSubmit) {
            callback();
        }
    }, [errors]);

    const handleSubmit = (event) => {
        if (event) {
            event.preventDefault();
        }
        setErrors(validate(values));
        setIsSubmit(true);
    };

    const handleChange = event => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    return {
        handleSubmit,
        handleChange,
        values,
        errors
    };
};

export default useForm;
