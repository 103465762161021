import React, { useState, useEffect } from 'react';
import axios from 'axios';
// MUI Components
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// stripe
import {
  useStripe,
  useElements,
  CardElement,
} from '@stripe/react-stripe-js';
// Util imports
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
// Custom Components
import CardInput from '../card-input/card-input';
import CustomButton from '../custom-button/custom-button';
import { getCompanyTheme } from '../../utils/company';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles({
  root: {
    // maxWidth: 500,
    // margin: '35vh auto',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'flex-start',
  },
  div: {
    display: 'flex',
    flexDirection: 'row',
    // alignContent: 'flex-start',
    justifyContent: 'center',
    marginTop: '10px',
  },
  button: {
    margin: '2em auto 1em',
  },
  paymentImg: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  paymentImgMob: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'start',
  },
  cardImg: {
    width: '373px',
    height: '37px',
  },
  cardImgMob: {
    width: '100%',
    height: 'auto',
  },
  alertText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#484848',
  },
});

function StripeForm({
  email,
  priceId,
  serverUrl,
  customerId,
  planDuration,
  company,
}) {
  //   const classes = useStyles();
  const xs = useMediaQuery('(max-width: 631px)');
  const classes = useStyles({ xs });
  const { t } = useTranslation();
  // State
  const [name, setName] = useState('');
  const [open, setOpen] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [modalError, setModalError] = useState(null);

  const monthly = planDuration === 'MONTHLY' ? 1 : 0;
  const yearly = planDuration === t('yearly') ? 1 : 0;
  const lifetime = planDuration === t('lifetime') ? 1 : 0;
  // console.log(yearly);

  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();

  //   const handleSubmitPay = async (event) => {
  //     if (!stripe || !elements) {
  //       // Stripe.js has not yet loaded.
  //       // Make sure to disable form submission until Stripe.js has loaded.
  //       return;
  //     }

  //     const res = await axios.post('http://localhost:3000/pay', {
  //       email: email,
  //     });

  //     const clientSecret = res.data['client_secret'];

  //     const result = await stripe.confirmCardPayment(clientSecret, {
  //       payment_method: {
  //         card: elements.getElement(CardElement),
  //         billing_details: {
  //           email: email,
  //         },
  //       },
  //     });

  //     if (result.error) {
  //       // Show error to your customer (e.g., insufficient funds)
  //       console.log(result.error.message);
  //     } else {
  //       // The payment has been processed!
  //       if (result.paymentIntent.status === 'succeeded') {
  //         console.log('Money is in the bank!');
  //         // Show a success message to your customer
  //         // There's a risk of the customer closing the window before callback
  //         // execution. Set up a webhook or plugin to listen for the
  //         // payment_intent.succeeded event that handles any business critical
  //         // post-payment actions.
  //       }
  //     }
  //   };

  const handleClose = () => {
    setOpen(false);
    // clearErrors();
    setModalError(null);
    // console.log('handleclose', open);
  };

  const handleOpen = () => {
    setOpen(true);
    // clearErrors();
    // console.log('handleopen', open);
    return open;
  };

  useEffect(() => {
    // Check for register error
    // if (error.id === 'LOGIN_FAIL') {
    //   // console.log('err', error);
    //   setModalError(t(error.msg));
    //   // console.log(error.msg);
    //   // console.log(gsc);
    //   if (error.msg === 'GSCNotValid') {
    //     showGsc(true);
    //   }
    // } else {
    //   setModalError(null);
    //   // showGsc(false);
    // }
    // If authenticated, close modal
    // if (open) {
    //   handleToggle();
    // }
  }, [modalError]);

  const modal = (
    <Dialog open={handleOpen} onClose={handleClose}>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          className={classes.alertText}
        >
          {/* {returnErrorMessage(modalError)} */}
          {t('payment_failed')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <CustomButton
          onClick={handleClose}
          backgroundColor={getCompanyTheme(company)}
        >
          {t('continue')}
        </CustomButton>
      </DialogActions>
    </Dialog>
  );

  const handleSubmitSub = async (event) => {
    event.preventDefault();
    setSubmitting(true);
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      setSubmitting(false);
      return;
    }

    try {
      const result = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
        billing_details: {
          email: email,
          name: name,
        },
        metadata: {
          store: company,
        },
      });

      // console.log(result.paymentMethod.id);

      if (result.error) {
        // create a modal
        setModalError('Payment failed');
        console.log(result.error.message);
      } else {
        setModalError(null);
        const token = localStorage.getItem('token');
        // let unmounted = false;
        //headers
        const config = {
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin':
              'https://' + window.location.host,
            Authorization: `Bearer ${token}`,
          },
        };

        // Request body
        const body = JSON.stringify({
          paymentMethodId: result.paymentMethod.id,
          customerId: customerId,
          priceId: priceId,
          company: company,
          monthly: monthly,
          yearly: yearly,
          lifetime: lifetime,
        });

        const res = await axios.post(
          serverUrl + '/ecommerce/createSubscription',
          body,
          config,
        );

        const { status, expiration } = res.data;
        if (status === 0) {
          // console.log('payment sucessful');
          setModalError(null);
          history.push(`/${company}/success`, {
            color: getCompanyTheme(company),
            expiry_date: expiration,
            company: company,
          });
        } else {
          console.log('payment failed', res);
          setModalError('Payment failed');
        }
        // eslint-disable-next-line camelcase
        //   const { client_secret, status } = res.data;

        //   if (status === 'requires_action') {
        //     stripe
        //       .confirmCardPayment(client_secret)
        //       .then(function (result) {
        //         if (result.error) {
        //           console.log('There was an issue!');
        //           console.log(result.error);
        //           // Display error message in your UI.
        //           // The card was declined (i.e. insufficient funds, card has expired, etc)
        //         } else {
        //           console.log('You got the money!');
        //           // Show a success message to your customer
        //         }
        //       });
        //   } else {
        //     console.log('You got the money!');
        //     // No additional information was needed
        //     // Show a success message to your customer
        //   }
      }
    } catch (err) {
      setModalError('Payment failed');
    }
    setSubmitting(false);
  };

  return (
    <div>
      <Card className={classes.root}>
        <CardContent className={classes.content}>
          <div
            className={
              xs ? classes.paymentImgMob : classes.paymentImg
            }
          >
            <strong>{t('stripe_text')}</strong>
            <img
              src="https://meditationcontent.blob.core.windows.net/meditationcontent/estore/v2/+payment-img.png"
              alt="payment"
              // style={{ width: '373px', height: '37px' }}
              className={xs ? classes.cardImgMob : classes.cardImg}
            />
          </div>
          <TextField
            label={t('full_name')}
            id="outlined-name-input"
            //   helperText={`Email you'll recive updates and receipts on`}
            margin="normal"
            variant="standard"
            //   type="email"
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
          />
          <CardInput />
          <div className={classes.div}>
            {/* <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleSubmitPay}
          >
            Pay
          </Button> */}
            <CustomButton
              // variant="contained"
              // color="primary"
              // className={classes.button}
              disabled={!stripe || !elements || submitting}
              onClick={handleSubmitSub}
              backgroundColor={getCompanyTheme(company)}
            >
              {!stripe || !elements || submitting ? (
                <CircularProgress
                  size={20}
                  style={{ color: 'white' }}
                />
              ) : (
                t('subscribe')
              )}
            </CustomButton>
          </div>
        </CardContent>
      </Card>
      {modalError ? modal : null}
    </div>
  );
}

export default StripeForm;
