import React from 'react';

class GoogleCloseBrowser extends React.Component {
  constructor() {
    super();
    var query = window.location.search.substring(1);
    localStorage.setItem('query', query);
    window.close();
  }
  render() {
    return <h3>Redirecting...</h3>;
  }
}

export default GoogleCloseBrowser;
