import React from 'react';
import { useTranslation } from 'react-i18next';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import { useRouteMatch, useParams, useLocation } from 'react-router-dom';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.common.black,
    position: 'fixed',
    left: 0,
    bottom: 0,
    width: '100%',
    color: theme.palette.common.white,
    textAlign: 'center',
    height: 47,
    lineHeight: '14px',
  },
  footerContent: {
    paddingTop: 14,
    paddingBottom: 19,
    fontWeight: 400,
    fontSize: 12,
  },
  select: {
    '&:before': {
      borderColor: 'white',
      backgroundColor: 'trasnparent',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '12px',
      lineHeight: '20px',
      letterSpacing: '0.25px',
      color: '#484848',
      // backgroundColor: 'white',
    },
    '::focused': {
      borderColor: 'white',
      backgroundColor: 'trasnparent',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '12px',
      lineHeight: '20px',
      letterSpacing: '0.25px',
      color: '#484848',
      // backgroundColor: 'white',
    },
    '&:after': {
      borderColor: 'white',
      backgroundColor: 'white',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '12px',
      lineHeight: '20px',
      letterSpacing: '0.25px',
      color: '#484848',
      // backgroundColor: 'white',
    },
    '& .MuiSelect-select': {
      backgroundColor: 'white',
      border: 'white',
    },
    '& .MuiSelect-icon': {
      backgroundColor: 'white',
      border: 'white',
    },
    borderColor: 'white',
    backgroundColor: 'white',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    color: '#484848',
    // 'MuiSelect-select: focus': {
    //   backgroundColor: 'trasnparent',
    // },
  },
  text: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    color: '#484848',
  },
}));

function setLanguage(currentLang, { en, cn, fr, nl, de }, i18n) {
  var targetLang = undefined;
  var changeLanguage = true;

  Object.entries({ en, cn, fr, nl, de }).map((lang) => {
    if (lang[1] != undefined) {
      targetLang = lang[0];

      if (lang[0] == currentLang) {
        changeLanguage = false;
        return;
      }
    }
  });

  if (changeLanguage) {
    i18n.changeLanguage(targetLang);
  }
}

function LanguageDropdown({ en, cn, fr, nl, de }) {
  const { i18n } = useTranslation();
  const classes = useStyles();

  setLanguage(i18n.language, { en, cn, fr, nl, de }, i18n);

  return (
    <div style={{ marginRight: '10px' }}>
      <Select
        value={i18n.language}
        onChange={(e) => i18n.changeLanguage(e.target.value)}
        className={classes.select}
      >
        {en ? (
          <MenuItem className={classes.text} value="en">
            EN
          </MenuItem>
        ) : null}
        {cn ? (
          <MenuItem className={classes.text} value="cn">
            CN
          </MenuItem>
        ) : null}
        {fr ? (
          <MenuItem className={classes.text} value="fr">
            FR
          </MenuItem>
        ) : null}
        {nl ? (
          <MenuItem className={classes.text} value="nl">
            NL
          </MenuItem>
        ) : null}
        {de ? (
          <MenuItem className={classes.text} value="de">
            DE
          </MenuItem>
        ) : null}
      </Select>
    </div>
  );
}
export default LanguageDropdown;
