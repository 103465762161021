import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.common.black,
    position: 'fixed',
    left: 0,
    bottom: 0,
    width: '100%',
    color: theme.palette.common.white,
    textAlign: 'center',
    height: 47,
    lineHeight: '14px',
  },
  footerContent: {
    paddingTop: 14,
    paddingBottom: 19,
    fontWeight: 400,
    fontSize: 12,
  },
}));

export default function Footer() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <footer className={classes.footer}>
      <div className={classes.footerContent}>
        {t('copyright_text')}
      </div>
    </footer>
  );
}
