import { createTheme } from '@material-ui/core/styles';

export default createTheme({
  palette: {
    common: {
      white: '#FFFFFF',
      black: '#484848',
      blue: '#296DC0',
    },
  },
});
