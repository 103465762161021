import React, { useState } from "react"
import "./changepassword.css"
import useForm from "./useForm.js";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    Grid,
    TextField,
    InputAdornment,
    useMediaQuery
} from '@material-ui/core';
import CustomButton from '../../components/custom-button/custom-button.jsx';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
      padding: '0px',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      // marginTop: theme.spacing(3),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    assistedText: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '10px',
      lineHeight: '12px',
      color: 'rgba(0, 0, 0, 0.6)',
      marginLeft: '14px',
    },
    alertText: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '16px',
      lineHeight: '19px',
      color: '#484848',
    },
    visibilityIcon: {
      cursor: 'pointer',
    },
  }));
  

const ChangePassword = (props) => {
    const CHANGE_PASSWORD_URL = "/account/update-password";

    const [passwordVisible, setPasswordVisible] = useState(false);

    const sm = useMediaQuery('(max-width: 430px)');

    const classes = useStyles({ sm });

    const validate = (_values) => {
        let _errors = {};
        if (!_values.password) {
            _errors.password = "Password is required";
        }
        return _errors;
    }

    const changePassword = async () => {
        if (!values.password || values.password.length < 8) {
            toast.error("Password must have at least 8 characters!");
            return;
        }

        const urlSearchString = window.location.search;

        const params = new URLSearchParams(urlSearchString);

        let payload = params.get('payload');

        if (!payload) {
            toast.error("Unauthorized");
            return;
        }

        let decoded = JSON.parse(atob(payload));

        let body = {
            passwordUpdateToken: decoded.passwordUpdateToken,
            newPassword: values.password,
        }

        try {
            const res = await axios.post(decoded.server_url + CHANGE_PASSWORD_URL, body);
            if (res.status === 200 && res.data?.status == 0) {
                toast.success("Password updated successfully!");
            } else {
                toast.error(res.data?.message)
            }
        } catch (e) {
            toast.error("Unauthorized")
        }
    }

    const { values, errors, handleChange, handleSubmit } = useForm(changePassword, validate);

    return (
        <div className="login-form-container">
            <form className="login-form" onSubmit={handleSubmit}>
                <div className="login-form-content">
                    <div>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            <img style={{ width: '250px' }} src={"/logo.png"} />
                        </div>
                    </div>

                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            name="password"
                            label={'Enter new password'}
                            type={passwordVisible ? 'text' : 'password'}
                            id="password"
                            autoComplete="current-password"
                            value={values.password}
                            onChange={handleChange}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {passwordVisible ? (
                                            <VisibilityOffIcon
                                                className={classes.visibilityIcon}
                                                onClick={() => setPasswordVisible(false)}
                                            />
                                        ) : (
                                            <VisibilityIcon
                                                className={classes.visibilityIcon}
                                                onClick={() => setPasswordVisible(true)}
                                            />
                                        )}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>

                    <br></br>
                    <CustomButton
                        type="submit"
                        backgroundColor={'green'}
                        style={{ width: '90px', marginBottom: '14px' }}
                    >
                        {(
                            'Confirm'
                        )}
                    </CustomButton>
                </div>
            </form>
            <ToastContainer />
        </div>
    )
}

export default ChangePassword;
