import React, { useEffect } from 'react';
import { Grid, makeStyles, useMediaQuery } from '@material-ui/core';
// import { makeStyles } from '@material-ui/core/styles';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import CompanyLogo from '../../../components/company-logo/company-logo';
import Header from '../../../components/header/header';
import Footer from '../../../components/footer/footer';
import CompanyDetailsFlex from '../../../components/company-details-flex/company-details-flex';
import CustomButton from '../../../components/custom-button/custom-button';
import {
  getCompanyTheme,
  getBackgroundImg,
  getLogo,
  getDuration,
} from '../../../utils/company';
import { BASE_CDN_URL } from '../../../utils/constants';
import {
  sendAmplitudeData,
  detectPlatform,
} from '../../../utils/amplitude';
import ActivateButton from '../../../components/activate-button/activate-button';
import SocialIcons from '../../../components/social-icons/social-icons';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: 'calc(100vh - 70px - 47px)',
    overflow: 'auto',
  },
  mainContainer: {
    height: '100%',
  },
  leftBackgroundImg: {
    backgroundImage: `url(${BASE_CDN_URL + '/grey-bg.png'})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
  },
  rightBackgroundImg: {
    backgroundImage: `url(${getBackgroundImg('amdtx')})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  leftContainer: {
    marginLeft: '41px',
    marginRight: '56px',
    marginTop: '54px',
    marginBottom: '20px',
  },
  textContainer: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#484848',
    marginTop: '43px',
  },
  storeContainer: {
    height: 'calc(100vh - 119px)',
  },
  buttonContainer: {
    marginTop: '81px',
    paddingBottom: '80px',
  },
  mobButtonContainer: {
    marginTop: '40px',
    paddingBottom: '80px',
  },
  imgContainer: {
    height: '37px',
    width: '90px',
  },
}));

function LandingPage() {
  const sm = useMediaQuery('(max-width: 960px)');
  const leftPaneSize = sm ? 12 : 5;
  const classes = useStyles({ sm });
  const match = useRouteMatch();
  const history = useHistory();
  const { company } = match.params;
  const { t } = useTranslation();
  const platform = detectPlatform();

  useEffect(() => {
    sendAmplitudeData('landing_page_viewed', {
      company: company,
      device_platform: platform,
      time: new Date().toISOString(),
      origin: window.location.origin,
    });

    localStorage.setItem('sid', null);
    localStorage.setItem('group', null);
    localStorage.setItem('phase', null);
    localStorage.setItem('store', null);
  });

  return (
    <React.Fragment>
      <Header en cn fr de />
      <div className={classes.root}>
        <Grid container className={classes.mainContainer}>
          <Grid
            item
            xs={leftPaneSize}
            className={classes.leftBackgroundImg}
          >
            <div className={classes.leftContainer}>
              <CompanyLogo company={company} />
              <CompanyDetailsFlex
                slogan={t('default_slogan')}
                companyName={company}
              >
                <p>{t('default_landing1_p1')}</p>
                <p>{t('default_landing1_p2')}</p>
                <p>{t('default_landing2')}</p>
                <p>
                  <b>{t('default_landing3')} </b>
                </p>
              </CompanyDetailsFlex>
              <ActivateButton
                label={t('redeem_now')}
                backgroundColor={getCompanyTheme(company)}
                sm={sm}
                onClick={() => history.push(`/${company}/signup`)}
              />
            </div>
            <SocialIcons
              sm={sm}
              platform={platform}
              company={company}
            />
          </Grid>
          {sm ? null : (
            <Grid
              item
              xs={7}
              className={classes.rightBackgroundImg}
            ></Grid>
          )}
        </Grid>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default LandingPage;
