import React, { useEffect } from 'react';
import { Grid, makeStyles, useMediaQuery } from '@material-ui/core';
// import { makeStyles } from '@material-ui/core/styles';
import { useRouteMatch, useHistory } from 'react-router-dom';
import CompanyLogo from '../../../components/company-logo/company-logo';
import Header from '../../../components/header/header';
import Footer from '../../../components/footer/footer';
import CompanyDetailsFlex from '../../../components/company-details-flex/company-details-flex';
import CustomButton from '../../../components/custom-button/custom-button';
import { useTranslation } from 'react-i18next';
import {
  getCompanyTheme,
  getBackgroundImg,
  getLogo,
} from '../../../utils/company';
import { BASE_CDN_URL } from '../../../utils/constants';
import {
  sendAmplitudeData,
  detectPlatform,
} from '../../../utils/amplitude';
import ActivateButton from '../../../components/activate-button/activate-button';
import SocialIcons from '../../../components/social-icons/social-icons';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: 'calc(100vh - 70px - 47px)',
    overflow: 'auto',
  },
  mainContainer: {
    height: '100%',
  },
  leftBackgroundImg: {
    backgroundImage: `url(${BASE_CDN_URL + '/grey-bg.png'})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
  },
  rightBackgroundImg: {
    backgroundImage: `url(${getBackgroundImg('camh')})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  leftContainer: {
    marginLeft: '41px',
    marginRight: '56px',
    marginTop: '54px',
    marginBottom: '20px',
  },
  textContainer: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#484848',
    marginTop: '43px',
  },
  storeContainer: {
    height: 'calc(100vh - 119px)',
  },
  buttonContainer: {
    marginTop: '81px',
  },
  imgContainer: {
    height: '30px',
    width: '95px',
  },
  link1: {
    width: '135px',
    height: '41px',
  },
  link2: {
    width: '156px',
    height: '60px',
  },
  link2Mob: {
    width: '156px',
    height: '60px',
    position: 'relative',
    left: '-10px',
  },
  linkWrapper: {
    display: 'flex',
    alignSelf: 'flex-end',
    alignItems: 'center',
    marginLeft: '41px',
    marginBottom: '31px',
    marginTop: '150px',
  },
  linkWrapperMob: {
    display: 'flex',
    alignSelf: 'flex-start',
    // alignItems: 'center',
    marginLeft: '41px',
    flexDirection: 'column',
    marginTop: '50px',
    marginBottom: '50px',
  },
}));

function LandingPage() {
  const sm = useMediaQuery('(max-width: 960px)');
  const leftPaneSize = sm ? 12 : 5;
  const classes = useStyles({ sm });
  const match = useRouteMatch();
  const history = useHistory();
  const { company } = match.params;
  const { t } = useTranslation();
  //sid
  const params = new URLSearchParams(history.location.search);
  const sid = params.get('sid');
  const group = params.get('group');
  const phase = params.get('phase');
  const platform = detectPlatform();

  useEffect(() => {
    sendAmplitudeData('landing_page_viewed', {
      company: company,
      device_platform: detectPlatform(),
      time: new Date().toISOString(),
      origin: window.location.origin,
    });

    localStorage.setItem('store', null);
    localStorage.setItem('sid', sid);
    localStorage.setItem('raw_sid', +sid);
    localStorage.setItem('group', group);
    localStorage.setItem('phase', phase);
  });

  return (
    <React.Fragment>
      <Header en fr />
      <div className={classes.root}>
        <Grid container className={classes.mainContainer}>
          <Grid
            item
            xs={leftPaneSize}
            className={classes.leftBackgroundImg}
          >
            <div className={classes.leftContainer}>
              <CompanyLogo company={company} />
              <CompanyDetailsFlex
                slogan={t('camh_slogan')}
                companyName={company}
              >
                <p>{t('camh_landing1')}</p>
                <p>{t('camh_landing2')}</p>
                <p>{t('camh_landing3')}</p>
                <div>{t('am_love')}</div>
                <div>
                  <b>{t('activate_access')}</b>
                </div>
              </CompanyDetailsFlex>
              <ActivateButton
                label={t('access_am')}
                backgroundColor={getCompanyTheme(company)}
                sm={sm}
                onClick={() => history.push(`/${company}/signup`)}
              />
            </div>
            <SocialIcons
              sm={sm}
              platform={platform}
              company={company}
            />
          </Grid>
          {sm ? null : (
            <Grid
              item
              xs={7}
              className={classes.rightBackgroundImg}
            ></Grid>
          )}
        </Grid>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default LandingPage;
